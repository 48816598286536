<template>
  <div id="pricing">
    <div class="background-rectangle" />
    <BaseTitle pageTitle="計費方案" pageSubtitle="Pricing"></BaseTitle>
    <div class="pricing-wrapper">
       <!-- <div class="pricing-banner">
         <div class="pricing-banner-inner"></div>
       </div> -->
      <div class="pricing-container">
        <!-- 企業賣家 -->
        <div class="seller-wrapper">
          <div class="seller-container">
            <div class="seller-service-wrapper">
              <div class="seller-title">企業賣家</div>
              <div class="seller-subtitle">適合公司行號、有統一編號之商家</div>
            </div>
            <div class="pricing-section">
              <div class="pricing-title mt-0">
                每筆交易牌價 <span>2.5％</span>
              </div>
              <div class="cards"></div>
              <div class="section-description">支援 Apple Pay、Visa、Mastercard、JCB</div>
            </div>
            <div class="pricing-section">
              <div class="section-title">國外信用卡每筆交易牌價</div>
              <div class="pricing-title mb-0">
                <span>3.6％</span> 起
              </div>
            </div>
            <div class="pricing-section">
              <div class="section-title">每月交易額度上限</div>
              <div class="pricing-title">
                <span>80</span>萬元
              </div>
              <div class="section-description">可調整額度</div>
            </div>
          </div>
        </div>
        <!-- 個人賣家 -->
        <div class="seller-wrapper">
          <div class="seller-container personal">
            <div class="seller-service-wrapper">
              <div class="seller-title personal-bg">個人賣家</div>
              <div class="seller-subtitle">適合一般個人、<span>無統一編號</span>之商家</div>
            </div>
            <div class="pricing-section">
              <div class="pricing-title mt-0">
                每筆交易牌價
                <span>2.75％</span>
              </div>
              <div class="cards"></div>
              <div class="section-description">支援 Apple Pay、Visa、Mastercard、JCB</div>
            </div>
            <div class="pricing-section">
              <div class="section-title">國外信用卡每筆交易牌價</div>
              <div class="pricing-title mb-0">
                <span>3.9％</span> 起
              </div>
            </div>
            <div class="pricing-section">
              <div class="section-title">每月交易額度上限</div>
              <div class="pricing-title">
                <span>15</span>萬元
              </div>
              <div class="section-description">不可調整額度</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PricingFaq></PricingFaq>
    <ServiceApply></ServiceApply>
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter";
import BaseTitle from "@/components/BaseTitle";
import PricingFaq from "@/components/PricingFaq";
import ServiceApply from "@/components/ServiceApply";

export default {
  components: {
    BaseFooter,
    BaseTitle,
    PricingFaq,
    ServiceApply
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#pricing {
  @media screen and (max-width: 1000px) {
    padding-top: 50px;
  }
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 460px;
  background-color: $lightBlue;
  border-radius: 0 100px 100px 100px;
  z-index: -1;
  @media screen and (max-width: 1000px) {
    border-radius: 0 50px 50px 50px;
    height: 400px;
  }
}


.pricing-wrapper {
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1000px) {
    padding: 50px 50px;
  }
  @media screen and (max-width: 800px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 500px) {
    padding: 50px 20px;
  }
}

.pricing-banner {
  position: relative;
  width: 100%;
  height: 400px;
  background-image: linear-gradient(60deg, #004ff8 3%, #49c8b8 53%);
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  background-position: center;
  margin-bottom: 50px;
  box-shadow: 0 2px 8px 0 $boxShadow;
   @media screen and (max-width: 800px) {
    height: 300px;
  }
  @media screen and (max-width: 600px) {
    height: 250px;
  }
  @media screen and (max-width: 540px) {
    height: 180px;
  }
}

.pricing-banner-inner {
  position: absolute;
  height: 400px;
  width: 708px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('../assets/imgs/pricing/pricing-banner.png');
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1100px) {
    left: 20px;
    transform: none;
  }
   @media screen and (max-width: 1006px) {
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 800px) {
    width: 531px;
    height: 300px;
  }

  @media screen and (max-width: 600px) {
    width: 442px;
    height: 250px;
  }

   @media screen and (max-width: 540px) {
     background-image: url('../assets/imgs/pricing/pricing-banner-xs.png');
      width: 335px;
      height: 180px;
  }
}

.pricing-container {
    width: 100%;
    min-width: 320px ;
   display: flex;
   justify-content: center;
   @media screen and (max-width: 768px) {
    display: block;
  }
}

.seller-wrapper {
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.seller-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 $boxShadow;
  padding-bottom: 30px;
  background: linear-gradient(180deg, #2EB8FF 0%, #009CE5 100%);
  overflow: hidden;
  cursor: default;
  color: white;
  &:last-child {
    height: 100%;
  }
}

.personal {
  background-image: linear-gradient(180deg, #15e3c5, #3680ff);
  background: linear-gradient(180deg, #39B382 0%, #0B763F 100%);
}
.seller-service-wrapper {
  margin: 35px 44px 12px 44px;
  color: white;
  text-align: center;
  .seller-title {
    font-size: 24px;
    font-weight: 500;
    background-color: #004ff8;
    border-radius: 50px;
    padding: 10px;
  }

  .personal-bg {
    background-color: #11823D;
  }
  .seller-subtitle {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
    margin-top: 12px;
    span {
      font-weight: 500;
    }
  }
  .seller-service {
    display: flex;
    margin-bottom: 20px;
    .service-title {
      font-size: 14px;
      height: 20px;
    }
  }
}


.pricing-section {
  padding: 24px;
  margin: 10px 20px;
  text-align: center;
  background: white;
  border-radius: 20px;
  color: #464A53;
  @media screen and (max-width: 1000px) {
    padding: 30px 50px;
  }
  @media screen and (max-width: 610px) {
    padding: 30px 20px;
  }
  .section-title {
    font-size: 16px;
  }
  .section-description {
    font-size: 15px;
    height: 20px;
  }
  .pricing-title {
    margin: 6px 0;
    font-size: 16px;
    span {
      font-size: 30px;
      font-weight: bold;
      margin: 0 4px;
    }
    .pricing-subtitle {
      margin-top: 8px;
      .pricing-notice {
        margin-left: 4px;
        position: relative;
        cursor: pointer;
        width: 16px;
        height: 16px;
        background-size: cover;
        background-image: url('../assets/imgs/pricing/service-question.svg');
        &:hover {
          .pricing-notice-modal {
            opacity: 1;
          }
        }
        .pricing-notice-modal {
          padding: 12px;
          opacity: 0;
          position: absolute;
          top: 10px;
          left: 0;
          width: 200px;
          border-radius: 4px;
          background: black;
          color: white;
          font-size: 13px;
          z-index: 999;
        }
      }
    }
  }

  .cards {
    background-image: url('../assets/imgs/pricing/cards.png');
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 12px;
  }
  .mb-0 {
    margin-bottom:0;
  }
  .mt-0 {
    margin-top:0;
  }
}

.limit-notice{
  opacity: 0.56;
}

.notice-wrapper {
  border-top: 1px solid #f6fafb;
  padding-top: 20px;
  margin: 20px 0 50px 0;
  width: 696px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.transfer-notice {
  font-size: 14px;
  margin-top: 8px;
}

a {
  color: $mainBlue;
  &:hover {
    color: $deepBlue;
  }
}
</style>

