<template>
  <div id="apply-example">
    <div class="background-rectangle" />
    <BaseTitle pageTitle="如何申請" pageSubtitle="How to apply"></BaseTitle>
    <div class="example-wrapper">
      <div class="example-notice">
        以下申請範例僅供參考，請如實填寫實際商店實況，以利加速查核申請!
        為了保障您的交易安全，申請賣家審核時間最少需5個⼯作天（不含例假⽇、國定假⽇），感謝您的耐心等候！
      </div>
      <div class="tab-wrapper">
         <button
         v-for="(tab ,index) in tabs"
         :key="tab"
         :class="{cur:iscur===index}"
         @click="iscur=index" >
         {{tab.name}}
         </button>  
      </div> 
      <ApplyBusinessExample v-if="iscur===0"></ApplyBusinessExample>
      <ApplyPersonalExample v-if="iscur===1"></ApplyPersonalExample>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter";
import BaseTitle from "@/components/BaseTitle";
import ApplyBusinessExample from "@/components/ApplyBusinessExample";
import ApplyPersonalExample from "@/components/ApplyPersonalExample";

export default {
  data () {  
    return {  
      tabs: [{name: "企業賣家申請範例"}, {name: "個人賣家申請範例"}],
      iscur: 0,  
    }  
  },  
  components: {
    BaseFooter,
    BaseTitle,
    ApplyBusinessExample,
    ApplyPersonalExample
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#apply-example {
  @media screen and (max-width: 900px) {
    padding-top: 50px;
  }
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 450px;
  background-color: $lightBlue;
  border-radius: 0 100px 100px 100px;
  z-index: -1;
  @media screen and (max-width: 650px) {
    height: 550px;
  }
}

.example-wrapper {
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    padding: 50px 50px;
  }
  @media screen and (max-width: 500px) {
    padding: 50px 20px;
  }
}

.tab-wrapper {
  margin: 30px 0 30px 0;
  button {
    cursor: pointer;
    font-size: 18px;
    border: none;
    background: transparent;
    padding-bottom: 14px;
    margin-right: 30px;
    color: $subTitle;
    transition: all .3s ease-in-out;
    @media screen and (max-width: 500px) {
      font-size: 15px;
    }
    @media screen and (max-width: 375px) {
      margin-right: 12px;
      font-size: 14px;
    }
  }
  .cur {
    font-weight: bold;
    color: $textBlack;
    border-bottom: 2px solid $mainBlue;
  }  
}

</style>

