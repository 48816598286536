<template>
   <div id="us-fee">
    <section class="fees">
      <h2>Simple, convenient, safe and reliable, with no hidden fees.</h2>
    </section>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/us-layout.scss";

  .fees {
    padding: 80px;
    background-image: linear-gradient(135deg, #00C7FF, #2581C5);
    color: white;
    text-align: center;
    @media screen and (max-width: 1024px) {
        padding: 60px 50px;
    }
    @media screen and (max-width: 768px) {
        padding: 50px 30px;
    }
    @media screen and (max-width: 500px) {
        padding: 50px 20px;
    }
    h2 {
        font-size: 24px;
      }
  }

</style>
