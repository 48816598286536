<template>
   <footer id="us-footer">
      <div class="downloads">
        <div class="app-logo bg-contain"></div>
        <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener">
          <div class="ios-logo bg-contain"></div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank" rel="noopener">
          <div class="android-logo bg-contain"></div>
        </a>
      </div>
      <div class="terms">
        <a href="https://tap.ins.to/us/terms/privacy.html" target="_blank">Privacy Policy</a>
        <a href="https://tap.ins.to/us/terms/service.html" target="_blank">Service Agreement</a>
        <a href="https://tap.ins.to/us/terms/prohibit.html" target="_blank">Prohibition Agreement</a>
      </div>
      <h3>copyright © 2024 INSTO, LLC.</h3>
    </footer>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/us-layout.scss";

  #us-footer {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F2FCFF;
    @media screen and (max-width: 768px) {
        padding: 50px 30px;
    }
    @media screen and (max-width: 500px) {
        padding: 50px 20px;
    }
    .downloads {
      margin: 0;
    }
    h3 {
      margin:0;
      font-size: 12px;
      font-weight: normal;
    }
    .terms {
      margin: 12px auto 30px auto;
      @media screen and (max-width: 500px) {
        margin-bottom: 20px;
        }
      a {
        margin: 0 6px;
        text-decoration: underline;
        @media screen and (max-width: 500px) {
        display: block;
        text-align: center;
        margin: 12px 0px;
        }
      }
    }
  }

  .ios-logo {
    background-image: url("../../assets/imgs/download-app-store.png");
  }

  .android-logo {
    background-image: url('../../assets/imgs/download-google-play.png');
  }

  .app-logo {
    background-image: url("../../assets/imgs/app-logo.png");
  }
</style>
