<template>
  <div id="fcc-event-main" class="bg-cover">
    <div class="main-wrapper">
      <div class="main-text bg-contain"></div>
      <div class="main-title-wrapper bg-cover">
        <div class="title-wrapper">
          <h1 class="title-lg">
            個人賣家<br>信用卡收款服務
          </h1>
          <button id="fcc-click-main-acbtn" class="action-button" style="width: 300px;" v-on:click="download()">
            立即註冊成為賣家
          </button>
          <div class="downloads">
            <div class="app-logo bg-contain"></div>
            <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener" id="fcc-main-iosbtn">
              <div class="ios-logo bg-contain"></div>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank"
              rel="noopener" id="fcc-main-androidbtn">
              <div class="android-logo bg-contain"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('fcc-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/foreign-credit-card.scss";

#fcc-event-main {
  width: 100%;
  background-image: url('../../assets/imgs/event/foreign-card/fc-main-bg.png') ;
  height: 540px;
  position: relative;
  overflow: hidden;
  margin-top: 72px;
  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
  
  @media screen and (max-width: 768px) {
    height: 640px;
    background-image: url('../../assets/imgs/event/foreign-card/fc-main-bg-768.jpg') ;
  }
  @media screen and (max-width: 415px) {
    height: 640px;
    background-image: url('../../assets/imgs/event/foreign-card/fc-main-bg-390.png') ;
  }
}

.main-text {
  position: absolute;
  top: 0;
  left: 8%;
  width: 300px;
  height: 300px;
  background-image: url('../../assets/imgs/event/foreign-card/main-text.png') ;
  @media screen and (max-width: 1513px) {
    left: 4%;
    width: 300px;
    height: 300px;
  }
  @media screen and (max-width: 1314px) {
    left: 80px;
    width: 250px;
    height: 250px;
  }
  @media screen and (max-width: 900px) {
    left: 10%;
    width: 180px;
    height: 180px;
  }
  @media screen and (max-width: 600px) {
    top: -18px;
    left: -8px;
    width: 160px;
    height: 160px;
  }
}

.main-title-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 300px;
  width: 480px;
  height: 480px;
  background-image: url('../../assets/imgs/event/foreign-card/fc-prcing-bg.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  @media screen and (max-width: 1680px) {
    right: 240px;
  }
  @media screen and (max-width: 1440px) {
    right: 80px;
  }
  @media screen and (max-width: 1350px) {
    width: 440px;
    height: 440px;
    right: 100px;
  }
  @media screen and (max-width: 1024px) {
    right: 20px;
  }
 @media screen and (max-width: 768px) {
    width: 440px;
    height: 440px;
    right: 10px;
    top: 76%;
  }

  @media screen and (max-width: 616px) {
    width: 800px;
    height: 420px;
    top: 297px;
    left: 50%;
    transform: none;
    transform: translateX(-50%);
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    @media screen and (max-width: 616px) {
       margin-top: -50px;
      }
    @media screen and (max-width: 415px) {
       margin-top: -40px;
      }
    h1.title-lg {
      color: #014B93;
      margin-top: 0;
      @media screen and (max-width: 415px) {
        font-size: 44px;
      }
    }
  }
}

.downloads {
  margin-bottom: 0;
}

.app-logo {
  background-image: url("../../assets/imgs/app-logo.png");
}

.android-logo {
  background-image: url("../../assets/imgs/download-google-play.png");
}

.ios-logo {
  background-image: url("../../assets/imgs/download-app-store.png");
}


</style>
