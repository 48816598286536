import { render, staticRenderFns } from "./eventsMobile.vue?vue&type=template&id=615cdbe6&scoped=true&"
import script from "./eventsMobile.vue?vue&type=script&lang=js&"
export * from "./eventsMobile.vue?vue&type=script&lang=js&"
import style0 from "./eventsMobile.vue?vue&type=style&index=0&id=615cdbe6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615cdbe6",
  null
  
)

export default component.exports