<template>
  <div id="event-sellers">
    <div class="event-title-wrapper">
      <div class="title-sm">
        Our Clients
      </div>
      <div class="title-line"/>
      <div class="title-lg">
        什麼行業適合使用 INSTO Tap？
      </div>
    </div>
    <div class="seller-wrapper">
      <div class="seller-card">
        <div class="card-title">
          企業收款
        </div>
        <div class="title-line"/>
        <div class="title-text">
          適合<span>公司行號、有統一編號</span>之商家
        </div>
      </div>
      <!-- 個人 -->
      <div class="seller-card">
        <div class="card-title">
          個人收款
        </div>
        <div class="title-line"/>
        <div class="title-text">
          適合<span>一般個人、無統一編號</span>之商家
        </div>
      </div>
    </div>
     <a href="#" target="_blank" class="action-button dl-now" id="gtm-download-sellers">
        立即下載
      </a>
  </div>
</template>

<script>
export default {
  mounted() {
    let dl=document.getElementsByClassName('dl-now');
    for(let i = 0; i < dl.length; i++) {
      dl[i].onclick=function(){
          let userAgent = navigator.userAgent || navigator.vendor || window.opera;
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              this.href='https://apps.apple.com/tw/app/id1525691104';
              return true;
          } else if (/android/i.test(userAgent)) {
              this.href='https://play.google.com/store/apps/details?id=com.installments.instotap';
              return true;
          } else {
              window.scroll({
                  behavior: 'smooth',
                  left: 0,
                  top: document.body.scrollHeight
              });
          }
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/event-insto-tap.scss";

#event-sellers {
  padding: 80px 100px;
  background-image: linear-gradient(180deg, #11C9FF, #A0EDFC);
  text-align: center;
  @media screen and (max-width: 800px) {
    padding: 60px 50px;
  }
  @media screen and (max-width: 500px) {
    padding: 60px 20px;
  }
}

.event-title-wrapper {
  color: $white;
  .title-line {
    border-color: $white;
    margin-left: auto;
    margin-right: auto;
  }
}

.seller-wrapper {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.seller-card {
  margin-right: 20px;
  padding: 30px;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 0px 7px 23px #89E1FF;
  font-size: 16px;
  width: 400px;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    margin: 6px;
    width: 100%;
  }
  .card-title {
    font-size: 24px;
    color: $mainBlue;
  }
  .title-text {
    margin-top: 15px;
    span {
      color: $mainBlue;
      font-weight: bold;
    }
  }
  .title-line {
    width: 50px;
    height: 1px;
    border-top: 1px solid $mainBlue;
    margin: 12px 0;
  }
  .list-wrapper {
    margin-top: 30px;
    .list-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1.2px solid #2371FF;
      margin-right: 8px;
    }
    .list-container {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
  }
}

</style>
