<template>
   <header id="us-header">
      <router-link to="/us/home">
        <img src="../../assets/imgs/tap-logo.svg" height="30" alt="logo" class="logo" />
      </router-link>
      <div class="lang-selector">
        <img src="../../assets/imgs/us/lang.svg" height="16" alt="lang">
        <div class="lang-text">
          English-EN
        </div>
      </div>
    </header>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/us-layout.scss";

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .lang-selector {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 10px;
      border-radius: 50px;
      border: 1px solid #4a4a4a;
      .lang-text {
        margin-left: 4px;
      }
    }
  }


</style>
