<template>
  <div id="cs-line" v-show="!$route.path.includes('us/')">
<!--    <a href="line://ti/p/@315fgnje?oat_referrer=PROFILE" target="_blank" class="insto-line">-->
    <img src="../assets/imgs/line-icon.png" alt="insto cs line">
<!--    </a>-->
  </div>
</template>

<script>
export default {
  mounted() {
    let dl=document.getElementsByClassName('dl-now');
    for(let i = 0; i < dl.length; i++) {
      dl[i].onclick=function(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
          return true;
        }
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: document.body.scrollHeight
        });
        return false;
      }
    }

    document.getElementById('cs-line').onclick=function(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
          window.open('line://ti/p/@315fgnje?oat_referrer=PROFILE','_blank');
          return;
        }
        const modal = document.getElementById('cs-modal')
        modal.classList.add('show')
        const csbox = document.getElementById('cs-line')
        csbox.classList.add('hidden')
      }
  }
};
</script>


<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#cs-line {
  position: fixed;
  background-color: #deffec;
  padding: 16px;
  border-radius: 50px;
  bottom: 30px;
  right: 20px;
  box-shadow: 0 2px 4px 0 rgba(171, 171, 171, 0.5);
  cursor: pointer;
  transition: all .3s ease-in-out;
  z-index: 999;
  @media screen and (max-width: 768px) {
      padding: 12px;
      font-size: 0;
    }
  &:hover {
    transform: scale(1.1);
  }
  img {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    @media screen and (max-width: 768px) {
      width: 44px;
      height: 44px;
    }
  }
}

</style>
