<template>
  <div id="contact-us">
    <div class="background-rectangle" />
    <BaseTitle pageTitle="聯絡我們" pageSubtitle="Contact Us"></BaseTitle>
    <div class="contact-us-wrapper">
      <div class="form-wrapper">
        <div class="form-content">
          <p>
            我們非常重視您的意見，若您有任何疑問，請先參考網頁上
            <a
              href="https://support.ins.to/hc/zh-tw"
              target="_blank"
              rel="noopener noreferrer"
            >常見問題</a>，亦可來信聯絡客服人員，我們將竭誠為您服務。
          </p>
          <p>
            客服服務時間：
            <br />週一至週五早上10:00至下午6:00
            <br />國定假日、例假日除外
          </p>
        </div>
        <form id="contact-us-form">
          <div class="input-wrapper">
            <label>稱呼</label>
            <input id="name" type="text" placeholder="請輸入稱呼" required name="name" />
          </div>
          <div class="input-wrapper">
            <label>電子信箱</label>
            <input id="email" type="email" placeholder="請輸入電子信箱" required name="email" />
          </div>
          <div class="input-wrapper">
            <label>標題主旨</label>
            <input id="title" type="text" placeholder="請輸入主旨" required name="title" />
          </div>
          <div class="input-wrapper">
            <label>內容</label>
            <textarea
              id="content"
              style="overflow:auto; resize: none"
              placeholder="請輸入內容"
              required
              name="content"
            />
            <input id="submit" class="submit" type="submit" name="submit" value="確定發送" />
          </div>
        </form>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter";
import BaseTitle from "@/components/BaseTitle";
import $ from "jquery";

export default {
  components: {
    BaseFooter,
    BaseTitle
  },
  mounted: function() {
    let __busy = 0;
    $("#contact-us-form").submit(function(e) {
      e.preventDefault();
      if (__busy) return;
      let jdata = {
        request: {
          requester: { name: "name", email: "email" },
          subject: "subject",
          comment: { body: "test" }
        }
      };
      jdata.request.requester.name = $("#name").val();
      jdata.request.requester.email = $("#email").val();
      jdata.request.subject = "[Tap Contact Us] " + $("#title").val();
      jdata.request.comment.body = $("#content").val();
      jdata = JSON.stringify(jdata);
      __busy = 1;
      $.ajax({
        url: "https://insto.zendesk.com/api/v2/requests.json",
        datatype: "json",
        data: jdata,
        type: "POST",
        async: true,
        processData: true,
        contentType: "application/json; charset=UTF-8"
      })
        .done(function(response, status, jqXHR) {
          alert("謝謝您的留言，我們會盡快回覆您。");
          $("#contact-us-form")[0].reset();
        })
        .fail(function(jqXHR, status, errtxt) {
          alert("Connection error : " + errtxt);
        })
        .always(function(jqXHR) {
          __busy = 0;
        });
    });
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#contact-us {
  @media screen and (max-width: 1000px) {
    padding-top: 50px;
  }
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 560px;
  background-color: $lightBlue;
  border-radius: 0 100px 100px 100px;
  z-index: -1;
}

.contact-us-wrapper {
  padding: 0px 0px 80px 0px;
}

.form-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: 0 50px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  @media screen and (max-width: 500px) {
    padding: 0 30px;
  }
  .form-content {
    margin-top: 80px;
    width: 300px;
    margin-right: 100px;
    font-size: 14px;
    p {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 1000px) {
      margin-right: 0;
      width: 100%;
      margin-top: 20px;
    }
    @media screen and (max-width: 500px) {
      margin-right: 0;
      width: 100%;
      margin-top: 0px;
    }
  }
}

#contact-us-form {
  padding: 30px;
  width: 360px;
  border-radius: 20px;
  box-shadow: 0 1px 8px 0 $shadowBlue;
  background-color: white;
  label {
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.input-wrapper {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

label {
  display: block;
  font-size: 14px;
}

input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $bgLightGrey;
  padding: 12px 0;
  outline: none;
  font-size: 14px;
  margin-right: 8px;
  transition: 0.3s ease-in-out;
}

input:last-child {
  margin-right: 0;
}

input ::placeholder {
  color: $placeholderGrey;
  font-size: 14px;
}

input:focus {
  border-bottom: 1px solid $mainBlue;
}

.submit {
  background-color: $mainBlue;
  &:hover {
    transform: translateY(4px);
    box-shadow: 0 2px 10px rgba(47, 203, 255, 0.47);
    background-color: $textBtnBlue;
  }
}

input[type="submit"] {
  margin: 60px auto 0 auto;
  border: 0;
  display: block;
  cursor: pointer;
  color: $white;
  width: 200px;
  height: 36px;
  line-height: 36px;
  padding: 0;
  border-radius: 100px;
  box-shadow: 0 2px 6px rgba(47, 203, 255, 0.47);
  background-color: $mainBlue;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 320px) {
  input[type="submit"] {
    margin: 50px auto 0 auto;
    width: 100%;
  }
}

a {
  color: $mainBlue;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

a:hover {
  color: $textBtnBlue;
}

textarea {
  width: 100%;
  height: 80px;
  overflow-y: visible;
  margin-top: 12px;
  border: 1px solid $bgLightGrey;
  outline: none;
  padding: 12px;
  font-size: 14px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background: $white;
  border-radius: 4px;
      &:focus {
      border-bottom: 1px solid #00c8ff;
    }
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}
</style>
