<template>
  <div id="how-it-works">
    <div class="background-rectangle" />
    <BaseTitle pageTitle="如何使用" pageSubtitle="How it works"></BaseTitle>
    <ServiceMobile></ServiceMobile>
    <div class="service-wrapper">
      <div class="service-container">
        <div class="service-banner collect-cover"></div>
        <div class="service-title">信用卡/Apple Pay收款</div>
        <div class="service-subtitle">
          遠距收款、輕鬆發送訂單，客戶無需下載任何錢包、任何App即可付款
        </div>
        <a
          href="https://youtu.be/cwozjX4m1tQ"
          class="service-intro"
          target="_blank"
        >
          <div class="intro-text">如何操作、簡單上手</div>
          <svg
            width="16"
            height="16"
            class="intro-icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(1 1)" fill-rule="evenodd">
              <circle cx="6.5" cy="6.5" r="6.5" />
              <path
                d="M9.142 7.131L5.627 9.943a.5.5 0 01-.812-.39V3.928a.5.5 0 01.812-.39L9.142 6.35a.5.5 0 010 .781z"
              />
            </g>
          </svg>
        </a>
        <div class="service-steps">
          <div class="service-step-wrapper">
            <div class="service-img collect-01"></div>
            <div class="service-text">輸入金額</div>
          </div>
          <div class="next-step"></div>
          <div class="service-step-wrapper">
            <div class="service-img collect-02"></div>
            <div class="service-text">透過簡訊/郵件/通訊軟體<br />發送訂單</div>
          </div>
          <div class="next-step"></div>
          <div class="service-step-wrapper">
            <div class="service-img collect-03"></div>
            <div class="service-text">買家收到訂單<br />完成結帳</div>
          </div>
        </div>
      </div>
      <div class="service-container">
        <div class="service-banner tap-cover"></div>
        <div class="service-title">NFC 感應收款</div>
        <div class="service-subtitle">
          面對面交易，使用 Android
          NFC手機即可感應客人信用卡收款！每筆交易付款進行3D驗證、嚴防誤刷、盜刷。
        </div>
        <a
          href="https://youtu.be/4L-XDIRQ8Dg"
          class="service-intro"
          target="_blank"
        >
          <div class="intro-text">如何操作、簡單上手</div>
          <svg
            width="16"
            height="16"
            class="intro-icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(1 1)" fill-rule="evenodd">
              <circle cx="6.5" cy="6.5" r="6.5" />
              <path
                d="M9.142 7.131L5.627 9.943a.5.5 0 01-.812-.39V3.928a.5.5 0 01.812-.39L9.142 6.35a.5.5 0 010 .781z"
              />
            </g>
          </svg>
        </a>
        <div class="service-steps">
          <div class="service-step-wrapper">
            <div class="service-img tap-01"></div>
            <div class="service-text">輸入金額</div>
          </div>
          <div class="next-step"></div>
          <div class="service-step-wrapper">
            <div class="service-img tap-02"></div>
            <div class="service-text">
              將買家信用卡置於手機背面<br />進行 NFC 感應
            </div>
          </div>
          <div class="next-step"></div>
          <div class="service-step-wrapper">
            <div class="service-img tap-03"></div>
            <div class="service-text">感應成功<br />完成結帳</div>
          </div>
        </div>
      </div>
      <div class="service-container">
        <div class="service-banner qr-cover"></div>
        <div class="service-title">商店收款碼</div>
        <div class="service-subtitle">
          掃碼付款、遠距、當面交易So Easy。客戶無需下載任何 App
          或綁定任何錢包即可付款
        </div>
        <a
          href="https://youtu.be/R_G_dKyGEkg"
          class="service-intro"
          target="_blank"
        >
          <div class="intro-text">如何操作、簡單上手</div>
          <svg
            width="16"
            height="16"
            class="intro-icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(1 1)" fill-rule="evenodd">
              <circle cx="6.5" cy="6.5" r="6.5" />
              <path
                d="M9.142 7.131L5.627 9.943a.5.5 0 01-.812-.39V3.928a.5.5 0 01.812-.39L9.142 6.35a.5.5 0 010 .781z"
              />
            </g>
          </svg>
        </a>
        <div class="service-steps">
          <div class="service-step-wrapper">
            <div class="service-img qr-01"></div>
            <div class="service-text">賣家出示專屬商店收款碼</div>
          </div>
          <div class="next-step"></div>
          <div class="service-step-wrapper">
            <div class="service-img qr-02"></div>
            <div class="service-text">買家掃碼</div>
          </div>
          <div class="next-step"></div>
          <div class="service-step-wrapper">
            <div class="service-img qr-03"></div>
            <div class="service-text">買家輸入付款金額<br />完成結帳</div>
          </div>
        </div>
      </div>
    </div>
    <ServiceApply></ServiceApply>
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter";
import BaseTitle from "@/components/BaseTitle";
import ServiceMobile from "@/components/how-it-works/ServiceMobile";
import ServiceApply from "@/components/ServiceApply";

export default {
  components: {
    BaseFooter,
    BaseTitle,
    ServiceMobile,
    ServiceApply,
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#how-it-works {
  @media screen and (max-width: 1000px) {
    padding-top: 50px;
  }
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 460px;
  background-color: $lightBlue;
  border-radius: 0 100px 100px 100px;
  z-index: -1;

  @media screen and (max-width: 1000px) {
    border-radius: 0 50px 50px 50px;
    height: 400px;
  }

  @media screen and (max-width: 600px) {
    border-radius: 0 20px 20px 20px;
    height: 460px;
  }
}

.service-wrapper {
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1000px) {
    padding: 50px 50px;
  }

  @media screen and (max-width: 800px) {
    padding: 50px 30px;
    display: none;
  }

  @media screen and (max-width: 500px) {
    padding: 50px 20px;
  }
}

.service-banner {
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  background-position: center;
  margin-bottom: 50px;
  box-shadow: 0 2px 8px 0 $boxShadow;

  @media screen and (max-width: 800px) {
    height: 300px;
  }

  @media screen and (max-width: 600px) {
    height: 250px;
  }

  @media screen and (max-width: 540px) {
    height: 180px;
  }
}

.collect-cover {
  background-image: url("../assets/imgs/how-it-works/collect-cover-2023.jpg");
}

.tap-cover {
  background-image: url("../assets/imgs/how-it-works/tap-cover.jpg");
}

.qr-cover {
  background-image: url("../assets/imgs/how-it-works/qr-cover-2023.jpg");
}

.service-container {
  margin-bottom: 60px;

  .service-title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .service-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .service-intro {
    display: flex;
    align-items: center;
    justify-content: center;

    .intro-icon {
      fill: none;
      stroke: #0d8aed;
      transition: 0.3s ease-in-out;
      margin-left: 4px;
    }
  }

  a {
    color: #0d8aed;

    &:hover {
      color: $mainBlue;

      .intro-icon {
        transform: translateX(4px);
        stroke: $mainBlue;
      }
    }
  }
}

.service-steps {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .next-step {
    width: 12px;
    height: 20px;
    background-size: contain;
    background-image: url("../assets/imgs/how-it-works/next.svg");
    margin: 50px 20px 0;
  }

  .service-step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;

    .service-img {
      width: 100px;
      height: 100px;
      margin-bottom: 16px;
    }

    .service-text {
      font-size: 15px;
    }
  }
}

.collect-01 {
  background-image: url("../assets/imgs/how-it-works/collect-01.svg");
}

.collect-02 {
  background-image: url("../assets/imgs/how-it-works/collect-02.svg");
}

.collect-03 {
  background-image: url("../assets/imgs/how-it-works/collect-03.svg");
}

.tap-01 {
  background-image: url("../assets/imgs/how-it-works/tap-01.svg");
}

.tap-02 {
  background-image: url("../assets/imgs/how-it-works/tap-02.svg");
}

.tap-03 {
  background-image: url("../assets/imgs/how-it-works/tap-03.svg");
}

.qr-01 {
  background-image: url("../assets/imgs/how-it-works/qr-01.svg");
}

.qr-02 {
  background-image: url("../assets/imgs/how-it-works/qr-02.svg");
}

.qr-03 {
  background-image: url("../assets/imgs/how-it-works/qr-03.svg");
}
</style>
