<template>
  <div id="events">
    <div class="event-wrapper">
       <carousel
        paginationActiveColor="#00c8ff"
        :perPage="3"
        autoplay
        :autoplayTimeout="2500"
        easing="ease"
        loop
      >
       <slide id="event-ap">
          <router-link to="/event/applepay">
             <div class="event applepay">
             </div>
          </router-link>
        </slide>
         <!-- <slide>
          <router-link to="/pricing">
             <div class="event pricing">
             </div>
          </router-link>
        </slide> -->
        <slide id="event-ita">
           <router-link to="/event/insto-tap-app" target="_blank">
              <div class="event insto-tap-app">
              </div>
           </router-link>
        </slide>
        <slide id="event-nfc-video">
          <a href="https://youtu.be/CwMxxZf8wIs?si=LID0OkGdmLqJLnvA" target="_blank">
            <div class="event video">
            </div>
          </a>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#events {
  padding: 0 20px;
  @media screen and (max-width: 1006px) {
    margin: 0 0 50px 0;
  }
}
// .event-wrapper {
//   display: flex;
//   justify-content: center;
//   @media screen and (max-width: 800px) {
//      justify-content: flex-start;
//   }
// }

.slide-wrapper {
  background: transparent;
  // width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 220px;
  @media screen and (max-width: 1440px) {
    height: 220px;
  }
}
.VueCarousel-slide {
  margin-right: 8px; 
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1552px) {
    width: 100%;
  }
}

.event {
  border-radius: 20px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  will-change: transform;
  box-shadow: 0 3px 13px 0 $shadowBlue;
  height: 280px;
  font-size: 0;
  background: $mainBlue;
  transition: all 0.5s ease-in-out;
  background-size: cover;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 1600px) {
    height: 260px;
  }
  @media screen and (max-width: 1552px) {
    height: 220px;
  }
  @media screen and (max-width: 1400px) {
    height: 190px;
  }
  @media screen and (max-width: 1300px) {
    height: 175px;
  }
  @media screen and (max-width: 1200px) {
    border-radius: 12px;
    height: 167px;
  }
  @media screen and (max-width: 1100px) {
    height: 156px;
  }
  @media screen and (max-width: 1038px) {
    height: 150px;
  }
  @media screen and (max-width: 1006px) {
    height: 165px;
  }
}

/deep/ {
  .VueCarousel-wrapper {
    padding: 6px 0;
  }
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      button.VueCarousel-dot {
        padding: 0 !important;
        width: 30px !important;
        height: 5px !important;
        border-radius: 100px !important;
        background-clip: unset !important;
        box-sizing: unset !important;
        margin: 0 3px;
        outline: none !important;
      }
    }
  }
}

.video {
  background-image: url('../../assets/imgs/home/03.png');
}
.pricing {
  background-image: url('../../assets/imgs/home/01.png');
}
.insto-tap-app {
  background-image: url('../../assets/imgs/home/02.png');
}

.applepay {
  background-image: url('../../assets/imgs/home/04.png');
}
</style>
