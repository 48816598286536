<template>
  <div id="base-title">
    <div class="title-border" />
    <div class="page-title-wrapper">
      <div class="page-title">{{pageTitle}}</div>
      <div class="page-subtitle">{{pageSubtitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: { 
    pageTitle: {
      type: String,
      default: ''
    },
    pageSubtitle: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#base-title{
  cursor: default;
  display: flex;
  align-items: center;
  padding-top: 50px;
  .title-border {
    width: 8px;
    height: 50px;
    border-radius: 100px;
    background-image: linear-gradient(to bottom, $gradientGreen, $gradientdeepGreen 180%);
  }
  .page-title-wrapper {
    margin-left: 24px;
    .page-title {
      font-size: 20px;
      margin-bottom: 12px;
    }
    .page-subtitle {
      font-size: 14px;
      color: $subTitle;
    }
  }
}

</style>
