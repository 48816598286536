<template>
  <div id="ap-event-product">
    <h1 class="title-lg">Apple Pay 讓你的買家付款更 Easy</h1>
    <div class="payment-icon bg-contain"></div>
    <div class="product-container">
      <div class="ap-product-wrapper">
      <div class="ap-product-image ap-stand bg-cover"></div>
        <h1 class="title-md">實體立牌</h1>
        <h2 class="title-xs">
          掃碼付款，當面交易So Easy<br>
          客戶無需下載任何 App 或綁定任何錢包即可付款。
        </h2>
    </div>
    <div class="ap-product-wrapper">
      <div class="ap-product-image ap-qrcode bg-cover"></div>
        <h1 class="title-md">商店收款碼</h1>
        <h2 class="title-xs">
          掃碼付款，走到哪，收到哪<br>客戶無需下載任何 App 或綁定任何錢包即可付款。
        </h2>
    </div>
    <div class="ap-product-wrapper">
      <div class="ap-product-image ap-sendbill bg-cover"></div>
        <h1 class="title-md">遠距收款</h1>
        <h2 class="title-xs">
          遠距發送訂單，讓買家進行遠端結帳。
        </h2>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('ap-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/apple-pay.scss";

#ap-event-product {
  padding: 80px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 60px 10px;
  }
  .payment-icon {
    width: 300px;
    height: 58px;
    background-image: url('../../assets/imgs/event/apple-pay/payment-method-white.png');
    margin-top: 20px;
  }
  .product-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 80px;
    width: 100%;
    @media screen and (max-width: 768px) {
      margin-top: 40px;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .ap-product-wrapper {
    width: 33%;
    text-align: left;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  .ap-product-image {
    width: 100%;
    height: 500px;
    background-color:aquamarine;
  }
  .ap-stand {
    background-image: url('../../assets/imgs/event/apple-pay/ap-stand.png');
  }

  .ap-qrcode {
    background-image: url('../../assets/imgs/event/apple-pay/ap-qrcode.png');
  }

  .ap-sendbill {
    background-image: url('../../assets/imgs/event/apple-pay/ap-sendbill.png');
  }
}

</style>
