<template>
  <div id="event-faq">
    <div class="event-title-wrapper">
        <div class="title-sm">
          FAQs
        </div>
        <div class="title-line" />
        <div class="title-lg">
          常見問題
        </div>
      </div>
    <div class="faq-list" v-for="(item, index) in items" :key="index" @click="toggleAccordion(index)" :id="'event-faq-q'+index">
      <div class="faq-title-wrapper accordion" :id="'event-faq-q'+index">
        <div class="title-md">{{ item.title }}</div>
        <div class="accordion-icon bg-contain" :class="{ 'icon-active': activeAccordion === index }"></div>
      </div>
      <div class="panel" :key="index" :class="{ 'active': activeAccordion === index }">
          {{ item.content }}
        <div v-html="item.url" style="display:inline"></div>
        <img :src="item.img">
      </div>
    </div>
    <div class="faq-list">
      <a href="https://support.ins.to/hc/zh-tw" target="_blank" style="width:100%; color:#464a53">
        <button class="faq-title-wrapper accordion">
          <div class="title-md" >更多問題請點我 Go</div>
          <div class="arrow-icon bg-contain"></div>
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "如何申請？", content: "我們採全線上申請制！請在手機下載 INSTO Tap App > 完成註冊 > 就可以開始申請賣家囉！" },
        { title: "有任何額外的費用嗎？", content: "沒有任何隱藏額外費用，我們僅收取成交交易手續費！讓你輕輕鬆鬆入門擁有方便的收款工具。" },
        { title: "我想收國外卡，如何申請開通呢？", content: "在您初次提交賣家申請時，於最後一個步驟即可一併申請國外卡收款，方便又快速！或你也能在 App 首頁中間橫幅點選開通；或在 App 側邊選單>我的帳戶>收款方式>進行國外卡申請。*國外卡申辦需等候數個工作天審閱，審閱通過後會收到email開通繳費單，完成繳費後即可開通哦！" ,url:'<a href="https://support.ins.to/hc/zh-tw/articles/4408209327641" target="_blank" style="color:#0986F9; text-decoration:underline">詳細可參閱</a>'},
        { title: "刷卡完成後，多久能拿到錢？", content: "系統將於每週四，自動撥款8天前～14天前之款項（一～兩週前），且將於完成撥款後發送 App 推播 及 E-mail通知。",img:require('../../assets/imgs/event/insto-tap-app/withdrawal-image.png') },
        { title: "收款有多少交易使用額度呢？", content: "個人賣家每月有15萬的交易額度；企業賣家每月有80萬的交易額度。",url:'<a href="https://tap.ins.to/pricing" target="_blank" style="color:#0986F9; text-decoration:underline">詳細可參閱</a>'},
        { title: "有限制哪家銀行才能當撥款帳戶嗎？", content: "沒有哦！國內任何一間銀行、郵局、農漁會等銀行，都能作為INSTO的撥款帳戶！" },
        { title: "客人可以使用 Apple Pay 付款嗎？", content: "只要客人的 iPhone、iOS 系列產品中有綁定 Apple Pay，在他點開 INSTO 付款連結、或掃描 QRCode 時，結帳畫面就會出現 Apple Pay 付款的按鈕選項，點擊該選項後即可進行 Apple Pay 支付款項囉！（需使用Safari瀏覽器開啟付款畫面）",img:require('../../assets/imgs/event/insto-tap-app/ap-btn-example.png') },
      ],
      activeAccordion: null
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/event-insto-tap.scss";

#event-faq {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1552px) {
    padding: 60px 100px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }

  h1 {
    margin: 0;
  }

  .faq-list {
    width: 100%;
    border-bottom: 1px solid #464a53;
    padding: 12px 0;
    color: #464a53;

    a {
      color: #464a53;
    }

    .title-md {
      font-size: 20px;
      padding: 0;
      font-weight: normal !important;
      color: #464a53;
    }

    img {
      display: block;
      width: 50%;
      margin: 10px auto;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  .accordion {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.accordion-icon {
  cursor: pointer;
  background-image: url('../../assets/imgs/event/insto-tap-app/trigger-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  transition: all .3s ease-in-out;
  flex-shrink: 0;
  &:hover {
    transform: rotate(45deg);
  }
}

.panel {
  text-align: left;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 16px;
  line-height: 1.5;
}

.active {
  max-height: 100%;
}

.icon-active {
  transform: rotate(45deg);
}

.arrow-icon {
  background-image: url('../../assets/imgs/event/insto-tap-app/arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover {
    transform: translateX(-4px);
  }
}

.event-title-wrapper {
  text-align: center;
  .title-line {
    margin-left: auto;
    margin-right: auto;
  }
}

</style>
