<template>
  <div id="service-apply">
    <div class="service-apply-title">
        還不知道怎麼申請嗎？簡單3步驟、開始收款
      </div>
      <div class="step-container">
        <div class="step-wrapper">
          <div class="step-icon step-01"></div>
          <div class="step-text">免費下載 App<br>免費註冊</div>
        </div>
        <div class="step-wrapper">
          <div class="step-icon step-02"></div>
          <div class="step-text">申請成為賣家<br>提交資料</div>
        </div>
        <div class="step-wrapper">
          <div class="step-icon step-03"></div>
          <div class="step-text">審核成功<br>立即收款</div>
        </div>
      </div>
    <div class="apply-example-wrapper">
      <router-link to="/products/apply-example-business" class="button" id="service-apply-business">
        企業賣家申請範例
        <svg class="blue-arrow" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(1 1)" fill-rule="evenodd">
            <circle cx="7" cy="7" r="7" />
            <path d="M8 4.5l2.54 2.116a.5.5 0 010 .768L8 9.5h0M4 7h6.5" stroke-linecap="round" />
          </g>
        </svg>
      </router-link>
      <router-link to="/products/apply-example-personal" class="button" id="service-apply-personal"> 
        個人賣家申請範例
        <svg class="blue-arrow" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(1 1)" fill-rule="evenodd">
            <circle cx="7" cy="7" r="7" />
            <path d="M8 4.5l2.54 2.116a.5.5 0 010 .768L8 9.5h0M4 7h6.5" stroke-linecap="round" />
          </g>
        </svg>
      </router-link>
    </div>
    <div class="service-subtitle">
      目前申請量大，申請賣家審核時間最少需7個⼯作天（不含例假⽇、國定假⽇），感謝您的耐心等候！
      如有急需收款需求，請提早申請，以免耽誤您的收款時間！
    </div>
    <!-- <div class="service-apply-title">
      立即下載 App 成為新賣家<br>隨時隨地、即時收款
    </div>
    <a href="https://smarturl.it/ramkgk" target="_blank" class="button download-mobile">
      立即下載
    </a>
    <div class="downloads">
      <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener">
        <div class="ios-logo" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank" rel="noopener">
        <div class="android-logo" />
      </a>
    </div> -->
  </div>
</template>

<script>
  export default {
    mounted() {
      let dl = document.getElementsByClassName('download-mobile');
      for (let i = 0; i < dl.length; i++) {
        dl[i].onclick = function () {
          let userAgent = navigator.userAgent || navigator.vendor || window.opera;
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            this.href = 'https://apps.apple.com/tw/app/id1525691104';
            return true;
          } else if (/android/i.test(userAgent)) {
            this.href = 'https://play.google.com/store/apps/details?id=com.installments.instotap';
            return true;
          }
          return false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";

  #service-apply {
    text-align: center;
    margin-bottom: 50px;

    @media screen and (max-width: 800px) {
      padding: 0 20px;
    }

    .service-apply-title {
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: 20px;

      @media screen and (max-width: 800px) {
        font-size: 22px;

        span {
          font-size: 16px;
        }
      }

      span {
        font-size: 18px;
      }
    }

    .step-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      .step-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        @media screen and (max-width: 600px) {
          margin: 0 10px;
        }
        .step-icon {
          width: 66px;
          height: 66px;
          background-size: cover;
        }
        .step-01 {
          background-image: url('../assets/imgs/products/apply-step1.svg');
        }
        .step-02 {
          background-image: url('../assets/imgs/products/apply-step2.svg');
        }
        .step-03 {
          background-image: url('../assets/imgs/products/apply-step3.svg');
        }
        .step-text {
          margin-top: 12px;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
          color: #39B382;
          width: 100px;
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
        }
      }
    }

    .service-subtitle {
      margin: 20px auto 50px auto;
      max-width: 500px;
      font-size: 15px;
      line-height: 1.5;
    }

    .apply-example-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      a {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px;
        transition: .3s ease-in-out;

        &:hover {

          .blue-arrow {
            transform: translateX(4px);

          }
        }

        @media screen and (max-width: 600px) {
         max-width: 300px;
         margin: 8px auto;
        }
        @media screen and (max-width: 320px) {
         max-width: 100%;
        }
      }

      .blue-arrow {
        fill: none;
        stroke: white;
        transition: .3s ease-in-out;
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    }
  }

  .downloads {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    @media screen and (max-width: 600px) {
      display: none;
    }

    .ios-logo {
      height: 39px;
      width: 126px;
      background-image: url("../assets/imgs/download-app-store.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 8px;
    }

    .android-logo {
      height: 39px;
      width: 126px;
      background-image: url("../assets/imgs/download-google-play.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .download-mobile {
    display: none;

    @media screen and (max-width: 600px) {
      display: block;
      width: 200px;
      margin: 20px auto 40px auto;
    }
  }
</style>
