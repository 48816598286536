<template>
 <div id="cs-modal">
    <div class="modal-close" v-on:click="closed()"></div>
    <div class="modal-wrapper">
      <div class="modal-bg"></div>
      <div class="modal-text">
      <img src="../assets/imgs/line-qrcode.png" alt="line qrcode">
      <h1>
        <span>立即掃碼</span> 或 搜尋ID : <span>@insto</span>
        <br>加入 INSTO LINE 官方好友！
        <br>即可線上詢問，將由專人為您服務解答
      </h1>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
    closed() {
      const modal = document.getElementById('cs-modal')
      modal.classList.remove('show')
      const csbox = document.getElementById('cs-line')
      csbox.classList.remove('hidden')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#cs-modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 320px;
  background: #DEFFEC;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(10, 114, 140, 0.23);
  align-items: flex-end;
  z-index: 999;
   @media screen and (max-width: 768px) {
     width: 80%;
     height: auto;
     border-radius: 12px;
     .modal-wrapper {
        flex-direction: column;
      }
   }

  .modal-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .modal-close {
    width: 20px;
    height: 20px;
    background-image: url('../assets/imgs/modal-close.svg');
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .modal-bg {
    width: 320px;
    height: 320px;
    background-image: url('../assets/imgs/cs-team.png');
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
     @media screen and (max-width: 768px) {
        height: 200px;
        width: 100%;
        background-image: url('../assets/imgs/cs-team.jpeg');
     }
  }
  .modal-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: left;
    img {
      width: 120px;
      height: 120px;
      margin-bottom: 12px;
    }
    h1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      margin: 0;
      span {
        font-weight: bold;
        color: $gradientBlue;
      }
    }
  }

}

</style>
