<template>
  <div id="fcc-event-pricing" class="bg-cover">
    <div class="pricing-wrapper">
      <div class="pricing-discount">
        <div class="pricing-origin">
          <div class="pricing-origin-text">
            牌價
          </div>
          <div class="pricing-origin-amount">
            2.75%<span></span>
          </div>
        </div>
        <h1>
          1.99<span>%</span>
        </h1>
      </div>
      <h2>
        註冊申請輸入折扣碼『new2024』享首月優惠
      </h2>
      <button class="action-button" id="fcc-pricing-acbtn" v-on:click="download()">
        立即註冊成為賣家
      </button>
      <h3 class="title-xs">
        請知悉國外卡費率將有不同，
        <a href="https://tap.ins.to/pricing" id="fcc-pricing-prcingurl" target="_blank">
          點我查看詳細費率資訊
        </a><br>
        *INSTO 保留最後優惠更動權利。
      </h3>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('ap-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/foreign-credit-card.scss";
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&display=swap');

#fcc-event-pricing {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/imgs/event/foreign-card/fc-prcing-bg.jpg');
  @media screen and (max-width: 1024px) {
    padding: 20px 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
  .pricing-wrapper {
    font-size: bold;
    .pricing-discount {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-weight: 700;
      .pricing-origin {
        margin-bottom: 18px;
        color: #929292;
        .pricing-origin-amount {
          text-decoration: line-through;
          font-size: 32px;
          span {
            font-size: 20px;
            text-decoration: none !important;
          }
        }
        .pricing-origin-text {
          font-size: 15px;
        }
      }
    }
    h2 {
      font-size: 28px;
      // letter-spacing: 2px;
      margin: 0 0 20px 0;
    }
    h1 {
      font-size: 100px;
      margin: 0;
      color: #0986F9;
      font-weight: 900;
      span {
        font-size: 36px;
      }
    }
    h3 {
      line-height: 1.5;
      font-weight: normal;
      a {
        color: #0986F9;
        &:hover {
          color: #0A67BD;
        }
      }
    }
  }
}

</style>
