<template>
<div id="event-insto-tap">
  <!-- <EventModal></EventModal> -->
  <EventMain></EventMain>
  <EventProduct></EventProduct>
  <EventFeatures></EventFeatures>
  <EventUsers></EventUsers>
  <EventSellers></EventSellers>
  <EventSellerImage></EventSellerImage>
  <EventFaq></EventFaq>
  <EventDownload></EventDownload>
    <section class="event-footer">
      <a
        ref="nooper"
        href="https://support.ins.to/hc/zh-tw"
        class="link"
        target="_blank"
        >常見問題
      </a>
      <a
        ref="nooper"
        href="https://www.facebook.com/INSTO.Taiwan"
        class="link"
        target="_blank"
        >最新消息
        </a>
      <h3>copyright © 2020 installments,Inc.</h3>
    </section>
  </div>
</template>

<script>
import EventMain from "@/components/event-insto-tap/EventMain.vue"
import EventProduct from "@/components/event-insto-tap/EventProduct.vue"
import EventFeatures from "@/components/event-insto-tap/EventFeatures.vue"
import EventUsers from "@/components/event-insto-tap/EventUsers.vue"
import EventSellers from "@/components/event-insto-tap/EventSellers.vue"
import EventSellerImage from "@/components/event-insto-tap/EventSellerImage.vue"
import EventDownload from "@/components/event-insto-tap/EventDownload.vue"
import EventFaq from "@/components/event-insto-tap/EventFaq.vue"


export default {
  name:'insto-tap-app',
  metaInfo: {
    title: '信用卡收款App，免費申請，免月租費，隨時隨地，即時收款！',
    meta: [
        {
          property: 'og:title',
          content: '信用卡收款App，免費申請，免月租費，隨時隨地，即時收款！',
          template: chunk => `INSTO Tap |${chunk}`,
          vmid: 'og:title'
        }
      ]
   },
  components: {
    EventMain,
    EventProduct,
    EventFeatures,
    EventUsers,
    EventSellers,
    EventSellerImage,
    EventDownload,
    EventFaq
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
@import "./src/assets/sass/event-insto-tap.scss";

#event-insto-tap {
  margin-left: 0;
  cursor: default;
  position: relative;
}

.router-view-layout {
  width: 100%;
}

.event-footer {
  padding: 20px;
  background-color: #F6FAFB;
  text-align: center;
  color: #95A1AF;
  margin-bottom: 0;
  font-size: 12px;
  a {
    margin: 0 4px;
    color: #95A1AF;
    &:hover {
      color: #00C8FF;
    }
  }
  h3 {
   font-size: 12px; 
   font-weight: normal;
  }
}


</style>
