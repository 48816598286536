import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import '@/assets/sass/main.scss'
import VueCookie from 'vue-cookie'
import VueCarousel from 'vue-carousel'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'

Vue.use(VueCarousel);
Vue.use(VueScrollTo);
Vue.use(VueMeta);


import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

Vue.config.productionTip = false
Vue.use(VueCookie)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueMeta, {
  keyName: 'head'
})
