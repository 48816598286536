<template>
  <div id="business-apply-example" slot="select01">
    <div class="example-title">
      企業賣家申請範例
      <div class="example-subtitle">注意：如負責人非公司法定代表人，需額外檢附賣家申請委託代理授權書！授權書將於申請流程完成後，寄到您的電子信箱！</div>
    </div>
   <div class="example-table" v-for="table in tables" :key="table.title">
      <div class="title-wrapper">
        <div class="table-title">{{table.title}}</div>
        <div class="table-description">{{table.description}}</div>
        <div class="table-description" v-html="table.link"></div>
      </div>
      <div class="text-wrapper">
        <div class="table-text">{{table.text}}</div>
        <img :src="table.url">
      </div>
    </div>
    <div>
      <div class="example-table">
        <div class="title-wrapper">
          <div class="table-title">營業型態</div>
        </div>
        <div class="text-wrapper">
          <div v-for="(item, index) in storeInfos.storeType" :key="index">
            <input type="radio" :id="item" name="storeType" :value="item" v-model="storeType" class="table-radio">
            <label :for="item">{{item}}</label>
          </div>
        </div>
      </div>
      <div v-if="storeType ==='線上'">
        <div class="example-table" v-for="item in storeInfos.online" :key="item.title">
          <div class="title-wrapper">
            <div class="table-title">{{item.title}}</div>
            <div class="table-description">{{item.description}}</div>
            <div class="table-description" v-html="item.link"></div>
          </div>
          <div class="text-wrapper">
            <div class="table-text">{{item.text}}</div>
            <img :src="item.url">
          </div>
        </div>
      </div>
      <div v-if="storeType ==='實體'">
        <div class="example-table" v-for="item in storeInfos.offline" :key="item.title">
          <div class="title-wrapper">
            <div class="table-title">{{item.title}}</div>
            <div class="table-description">{{item.description}}</div>
            <div class="table-description" v-html="item.link"></div>
          </div>
          <div class="text-wrapper">
            <div class="table-text">{{item.text}}</div>
            <img :src="item.url">
          </div>
        </div>
      </div>
      <div v-if="storeType ==='皆有'">
        <div class="example-table" v-for="item in storeInfos.both" :key="item.title">
          <div class="title-wrapper">
            <div class="table-title">{{item.title}}</div>
            <div class="table-description">{{item.description}}</div>
            <div class="table-description" v-html="item.link"></div>
          </div>
          <div class="text-wrapper">
            <div class="table-text">{{item.text}}</div>
            <img :src="item.url">
          </div>
        </div>
      </div>
    </div>
    <div class="example-table" v-for="bank in banks" :key="bank.title">
      <div class="title-wrapper">
        <div class="table-title">{{bank.title}}</div>
        <div class="table-description">{{bank.description}}</div>
          <div class="table-notice">{{bank.notice}}</div>
        <div class="table-description" v-html="bank.link"></div>
      </div>
      <div class="text-wrapper">
        <div class="table-text">{{bank.text}}</div>
        <img :src="bank.url">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tables: [{
          title: "企業登記名稱",
          text: "盈士多小舖股份有限公司"
        },
        {
          title: "企業統一編號/扣繳單位統一編號",
          text: "52647183"
        },
        // {
        //   title: "營業登記城市",
        //   text: "台北市"
        // },
        // {
        //   title: "資本額（萬）",
        //   text: "100"
        // },
        // {
        //   title: "公司設立日期",
        //   text: "100年1月1日"
        // },
        {
          title: "商業/公司登記證明文件",
          description: "律師行業/會計師行業/醫院診所，請提交最新扣繳單位設立登記申請書 (統一編號編配通知書)",
          url: require('../assets/imgs/products/apply-example/business-file.jpg')
        },
        {
          title: "企業代表人中文姓名",
          text: "陳筱玲"
        },
        // {
        //   title: "代表人英文姓氏/姓名",
        //   text: "Siao Ling Chen"
        // },
        {
          title: "企業代表人身分/身分證字號",
          text: "本國/A234567890",
          description:'如代表人非本國籍，請填寫居留證號碼',
        },
        {
          title: "企業代表人身分證正面",
          url: require('../assets/imgs/products/apply-example/id-card-01.jpg')
        },
        {
          title: "企業代表人身分證反面",
          url: require('../assets/imgs/products/apply-example/id-card-02.jpg')
        },
        {
          title: "商店名稱（中文）",
          description: "顯示於付款頁面與客戶信用卡帳單上，長度限14字元",
          text: "盈士多雜貨小舖"
        },
        {
          title: "商店名稱（英文）",
          description: "顯示於客戶信用卡帳單上，長度限14字元",
          text: "INSTOShop"
        },
      ],
      storeType: '線上',
      storeInfos: {
        storeType: [
          "線上", "實體", "皆有"
        ],
        online: [
          {
            title: "聯絡城市/區域/地址",
            text: "台北市中正區ＯＯ路Ｏ號Ｏ樓"
          },
           {
          title: "商店聯絡電話",
          description: "若無市話可填寫手機號碼",
          text: "01-23456789"
          },
          {
            title: "商店網址",
            text: "https://tap.ins.to"
          },
          {
            title: "商店網站管理頁面",
            description: "可證明該商店網站為企業所有，例如：電子商城後台、蝦皮商場管理後台、社群平台後台",
            url: require('../assets/imgs/products/apply-example/back-end.jpg')
          },
        ],
        offline: [{
            title: "營業城市/區域/地址",
            text: "台北市中正區ＯＯ路Ｏ號Ｏ樓"
          },
           {
          title: "商店聯絡電話",
          description: "若無市話可填寫手機號碼",
          text: "01-23456789"
         },
         {
            title: "營業店面招牌",
            description: "如為『市集攤商』須檢附：攤位照",
            url: require('../assets/imgs/products/apply-example/store-01.jpg')
          },
          {
            title: "營業門牌照片",
            description: "如為『市集攤商』須檢附：攤位照",
            url: require('../assets/imgs/products/apply-example/store-02.jpg')
          },
          {
            title: "營業環境照1",
            description: "如為『計程車、包車行業』須檢附：駕駛之執業車牌照片",
            url: require('../assets/imgs/products/apply-example/store-03.jpg')
          },
          {
            title: "營業環境照2",
            description: "如為『計程車、包車行業』須檢附：駕駛之執業車牌照片",
            url: require('../assets/imgs/products/apply-example/store-04.jpg')
          },
        ],
        both: [{
          title: "商店網址",
          text: "https://tap.ins.to"
        },
        {
          title: "商店網站管理頁面",
          description: "可顯示為該企業所有之證明網站或截圖畫面",
          url: require('../assets/imgs/products/apply-example/back-end.jpg')
        },
        {
          title: "營業城市/區域/地址",
          text: "台北市中正區ＯＯ路Ｏ號Ｏ樓"
        },
         {
          title: "商店聯絡電話",
          description: "若無市話可填寫手機號碼",
          text: "01-23456789"
        },
        {
            title: "營業店面招牌",
            description: "如為『市集攤商』須檢附：攤位照",
            url: require('../assets/imgs/products/apply-example/store-01.jpg')
          },
          {
            title: "營業門牌照片",
            description: "如為『市集攤商』須檢附：攤位照",
            url: require('../assets/imgs/products/apply-example/store-02.jpg')
          },
          {
            title: "營業環境照1",
            description: "如為『計程車、包車行業』須檢附：駕駛之執業車牌照片",
            url: require('../assets/imgs/products/apply-example/store-03.jpg')
          },
          {
            title: "營業環境照2",
            description: "如為『計程車、包車行業』須檢附：駕駛之執業車牌照片",
            url: require('../assets/imgs/products/apply-example/store-04.jpg')
          },
      ],
      },
      banks: [{
          title: "主要販售商品類別",
          text: "商品買賣"
        },
        {
          title: "商品/服務說明",
          description: "請詳述販售商品內容，最多1000字元",
          text: "本店主要經營日韓服飾專業代購，由板娘親自飛至日本、韓國進行選購，購買通路包含 Outlet、Shopping mall、潮牌店等，代購品項應有盡有，服飾、包包、鞋子、飾品類等皆可代購。我們不賣假貨、價格絕對優惠，買家可透過Line官方帳號、IG粉絲團聯繫下訂。"
        },
         {
          title: "檢附價格表",
          description: "請檢附『完整』價格表",
          url: require('../assets/imgs/products/apply-example/pricing.jpg')
        },
        {
          title: "檢附其他資料（選填）",
          description: "請檢附額外資料加速申請審核流程：如為『計程車行業』須檢附：執業登記證/如為『包車行業』須檢附：駕照/如為『律師行業』須檢附：律師證書、律師證/如為『直銷業者』須檢附：經銷證明文件",
        },
        {
          title: "申請國外卡",
          link: "如欲申請國外卡，請詳閱<a href='https://tap.ins.to/terms/foreign-card.html' target='blank' style='color:#00c8ff; text-decoration:underline'>國外卡交易注意事項</a>，並填寫申請國外卡服務之緣由，以供審核人員審核。",
          text: "本店有港澳台客戶，將貨品寄港澳地區，需要開通國外卡收費"
        },
        // {
        //   title: "相關職業證明（選填）",
        //   description: "請檢附以利加速申請審核流程",
        //   text: "如法律事務所業者，請提供「法律執照、開業證明」等；如為診所藥局業者，請提供「藥事執照相關證明」；非上述業者可略過"
        // },
        // {
        //   title: "商品平均價格區間",
        //   description: "僅為提供審查單位審核及確認賣家販售商品資料所用",
        //   text: "NT$100~NT$1000"
        // },
        {
          title: "金融機構代碼",
          text: "(123)OO銀行"
        },
        {
          title: "金融機構分行代碼",
          text: "(1234567)OO分行"
        },
        {
          title: "金融機構帳戶帳號",
          text: "000000001234567"
        },
        {
          title: "金融機構存摺封面（與上述帳戶一致）",
          notice: "金融帳戶戶名必須與公司名稱相同",
          link: "若選擇數位帳戶，請參閱<a href='https://support.ins.to/hc/zh-tw/articles/4404300856985-如何取得數位帳戶存摺封面-' target='blank' style='color:#00c8ff; text-decoration:underline'>如何取得數位帳戶存摺封面</a>",
          url: require('../assets/imgs/products/apply-example/bankbook.png')
        },
      ],
      iscur: 0
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#business-apply-example {
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 $boxShadow;
  background-color: #ffffff;
  overflow: hidden;
  cursor: default;
}
.example-title {
  font-size: 16px;
  padding: 20px;
  .example-subtitle {
    padding-top: 6px;
    font-size: 14px;
    color: #ffaa41;
  }
}

.example-table {
  display: flex;
  justify-content: center;
  align-items: stretch;
  @media screen and (max-width: 650px) {
     display: flex;
     flex-direction: column;
     &:nth-child(odd) {
       .title-wrapper {
          background: $white;
        }
        .text-wrapper {
          background: $white;
        }
     }
     &:nth-child(even) {
        .text-wrapper {
          background: $lightBlue;
        }
     }
     .text-wrapper {
       padding: 6px;
     }
  }

  .title-wrapper {
    width: 50%;
    padding: 12px 30px;
    background: $lightBlue;
    @media screen and (max-width: 650px) {
      width: 100%;
      padding: 12px 20px;
      .table-title {
        font-size: 13px;
        color: $subTitle;
      }
    }
    .table-description {
      margin-top: 4px;
      font-size: 13px;
      color: $subTitle;
    }
    .table-notice {
      margin-top: 4px;
      font-size: 13px;
      color: #ffaa41;
    }
  }
  .text-wrapper {
    width: 50%;
    padding: 12px 30px;
    background: $white;
    @media screen and (max-width: 650px) {
      width: 100%;
      padding: 0px 20px;
    }
    img {
      width: 220px;
      height: auto;
      @media screen and (max-width: 650px) {
        width: 100%;
        max-width: 220px;
        margin-bottom: 12px;
      }
    }
  }
}

.table-radio {
  margin-right: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
}

input[type='radio'] {
  margin: 0 10px 16px 0;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: $bgLightGrey;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid $bgLightGrey;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: $mainBlue;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2.4px solid $bgLightGrey;
  }
</style>
