<template>
  <div id="terms">
    <!-- <div class="background-rectangle" /> -->
    <BaseTitle pageTitle="法律條款" pageSubtitle="Terms"></BaseTitle>
    <div class="terms-wrapper">
      <div class="terms-list">
        <a href="/terms/member.html" class="link" target="_blank">會員條款</a>
      </div>
      <div class="terms-list">
        <a href="/terms/privacy.html" class="link" target="_blank">隱私權政策</a>
      </div>
      <div class="terms-list">
        <a href="/terms/payment.html" class="link" target="_blank">平台服務條款</a>
      </div>
      <div class="terms-list">
        <a href="/terms/authorized-store.html" class="link" target="_blank">特約商店服務條款</a>
      </div>
      <div class="terms-list">
        <a href="/terms/dispute.html" class="link" target="_blank">交易糾紛爭議處理辦法</a>
      </div>
      <div class="terms-list">
        <a href="/terms/prohibition.html" class="link" target="_blank">禁止和限制商品政策</a>
      </div>
      <div class="terms-list">
        <a href="/terms/foreign-card.html" class="link" target="_blank">國外卡交易注意事項</a>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
  import BaseFooter from "@/components/BaseFooter";
  import BaseTitle from "@/components/BaseTitle";

  export default {
    components: {
      BaseFooter,
      BaseTitle
    }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";

  #terms {
    @media screen and (max-width: 1000px) {
      padding-top: 50px;
    }
  }

  .background-rectangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 560px;
    background-color: $lightBlue;
    border-radius: 0 100px 100px 100px;
    z-index: -1;
  }

  .terms-wrapper {
    padding: 100px 100px 180px 100px;
    @media screen and (max-width: 1000px) {
      padding: 50px;
    }
    @media screen and (max-width: 400px) {
      padding: 30px;
    }
  }

  .terms-list {
    padding: 20px 20px 20px 0;
    border-bottom: 1px solid #eee;
    a {
      font-size: 15px;
    }
  }
</style>
