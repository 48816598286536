import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home";
import ContactUs from "./views/ContactUs";
// import Products from './views/Products'
import ProductQrcode from "./views/ProductQrcode";
import ProductTap from "./views/ProductTap";
import ProductSendbill from "./views/ProductSendbill";
import ProductMore from "./views/ProductMore";
import Pricing from "./views/Pricing";
import HowItWorks from "./views/HowItWorks";
import TermsList from "./views/TermsList";
import PageNotFound from "./views/PageNotFound";
import ApplyExamplePersonal from "./views/ApplyExamplePersonal";
import ApplyExampleBusiness from "./views/ApplyExampleBusiness";
import ForeignCreditCard from "./views/ForeignCreditCard";
import ApplePay from "./views/ApplePay";
// import EventTfbNewSeller from './views/EventTfbNewSeller'
import EventInstoTapApp from "./views/EventInstoTapApp";
import VueMeta from "vue-meta";
import USHome from "./views/USHome.vue";
import USIndividual from "./views/USIndividual.vue";
import USBusiness from "./views/USBusiness.vue";
Vue.use(Router);
Vue.use(VueMeta);

export default new Router({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    console.log(to, from);
    return (
      savedPosition || {
        x: 0,
        y: 0,
      }
    );
  },
  routes: [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/contact-us",
      component: ContactUs,
    },
    // {
    //   path: '/products',
    //   component: Products
    // },
    {
      path: "/products/insto-me",
      component: ProductQrcode,
    },
    {
      path: "/products/insto-tap",
      component: ProductTap,
    },
    {
      path: "/products/insto-collect",
      component: ProductSendbill,
    },
    {
      path: "/products/more-services",
      component: ProductMore,
    },
    {
      path: "/products/apply-example-business",
      component: ApplyExampleBusiness,
    },
    {
      path: "/products/apply-example-personal",
      component: ApplyExamplePersonal,
    },
    {
      path: "/pricing",
      component: Pricing,
    },
    {
      path: "/how-it-works",
      component: HowItWorks,
    },
    {
      path: "/terms-list",
      component: TermsList,
    },
    {
      path: "*",
      component: PageNotFound,
    },
    // {
    //   path: "/event/tfb-new-seller",
    //   name:'event',
    //   component: EventTfbNewSeller,
    // },
    {
      path: "/event/insto-tap-app",
      name: "event",
      component: EventInstoTapApp,
    },
    {
      path: "/event/foreign-credit-card",
      name: "event-fcc",
      component: ForeignCreditCard,
    },
    {
      path: "/event/applepay",
      name: "event-ap",
      component: ApplePay,
    },
    {
      path: "/usx/home",
      name: "us-home",
      component: USHome,
    },
    {
      path: "/usx/individual",
      name: "us-individual",
      component: USIndividual,
    },
    {
      path: "/usx/business",
      name: "us-business",
      component: USBusiness,
    },
  ],
});
