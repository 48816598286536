<template>
  <div id="app">
     <transition name="fade">
    <CookieAlert></CookieAlert>
     </transition>
    <BaseHeader />
    <BaseLine />
    <BaseLineModal />
    <transition name="fade">
      <router-view class="router-view-layout" />
    </transition>
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import CookieAlert from '@/components/CookieAlert'
import BaseLine from '@/components/BaseLine'
import BaseLineModal from '@/components/BaseLineModal'
import Vue from 'vue';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

export default {
  components: {
    BaseHeader,
    CookieAlert,
    BaseLine,
    BaseLineModal
  },
  name: 'App',
  metaInfo: {
    title: 'Click, Tap, Collect 輕碰卡片、輕鬆收款、你的行動收款App',
    titleTemplate: 'INSTO Tap | %s ',
  }
}
</script>

<style lang="scss">
@import '../src/assets/sass/main.scss';

#app {
  position: relative;
}

.router-view-layout{
  overflow: hidden;
  margin-left: 220px;
  width: calc(100% - 220px);
    @media screen and (max-width: 1000px) {
    width: 100%;
    margin-left: 0px;
  }
}

.fade-enter-active
{
  transition: opacity .5s;
}
.fade-enter/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
