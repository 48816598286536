<template>
  <footer v-show="$route.query.app !== '1'">
    <div class="footer-left">
      <div class="downloads">
        <a
          href="https://apps.apple.com/tw/app/id1525691104"
          target="_blank"
          rel="noopener"
          alt="ios download"
          ><div class="ios-logo"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.installments.instotap"
          target="_blank"
          rel="noopener"
          alt="android download"
        >
          <div class="android-logo"
        /></a>
      </div>
    </div>
    <div class="footer-right">
      <div class="footer-right-section">
        <div class="link-section">
          <div class="link-title">Service</div>
          <router-link to="/products/insto-tap" class="link">感應收款</router-link>
          <router-link to="/products/insto-me" class="link">商店收款碼</router-link>
          <router-link to="/products/insto-collect" class="link">遠距收款</router-link>
          <router-link to="/pricing" class="link">計費方案</router-link>
        </div>
        <div class="link-section">
          <div class="link-title">About</div>
          <a
            ref="nooper"
            href="https://ins.to/about-us"
            class="link"
            target="_blank"
            >關於我們</a
          >
        </div>
        <div class="link-section">
          <div class="link-title">Faq</div>
          <a
            ref="nooper"
            href="https://support.ins.to/hc/zh-tw"
            class="link"
            target="_blank"
            >常見問題</a
          >
          <router-link to="/contact-us" class="link">聯絡我們</router-link>
        </div>
        </div>
        <div class="footer-right-section">
          <div class="link-section">
            <div class="link-title">Terms</div>
            <router-link to="/terms-list" class="link">法律條款</router-link>
            <a href="/terms/privacy.html" class="link" target="_blank">隱私權政策</a>
          </div>
          <div class="link-section">
            <div class="link-title">Press</div>
            <a
              ref="nooper"
              href="https://ins.to/press"
              class="link"
              target="_blank"
              >媒體報導</a
            >
          </div>
          <div class="link-section">
            <div class="link-title">Follow us</div>
            <a
              ref="nooper"
              href="https://www.facebook.com/INSTO.Taiwan"
              class="link"
              target="_blank"
              >Facebook</a
            >
          </div>
      </div>
      <h3>copyright © 2020 installments,Inc.</h3>
      <h3>Taiwan INSTO Technologies Co., Ltd. Taipei, Taiwan</h3>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

footer {
  padding: 60px 50px;
  background: $lightBlue;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 900px) {
    padding: 50px;
  }
  @media screen and (max-width: 680px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
    padding: 30px;
  }
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 100px;
  @media screen and (max-width: 680px) {
    justify-content: flex-start;
    margin-right: 0;
    .downloads {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.ios-logo {
  height: 39px;
  width: 126px;
  background-image: url("../assets/imgs/download-app-store.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 8px;
   @media screen and (max-width: 680px) {
    margin-bottom: 0px;
    margin-right: 12px;
  }
}

.android-logo {
  height: 39px;
  width: 126px;
  background-image: url("../assets/imgs/download-google-play.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.footer-right {
  @media screen and (max-width: 680px) {
    margin-bottom: 30px;
  }
}

.footer-right-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}

.link-section {
  display: flex;
  flex-direction: column;
  margin: 0 80px 50px 0;
  width: 82px;
  &:last-child {
    margin-right:0;
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 30px;
    width: 100%;
  }
  .link-title {
    font-weight: bold;
    font-size: 13px;
    color: #565c68;
    text-transform: uppercase;
  }
  .link {
    margin-top: 12px;
    font-size: 13px;
    color: $subTitle;
  }
  a:hover {
    color: $mainBlue;
  }
}

h3 {
  margin: 5px 0 0 0 ;
  font-size: 12px;
  font-weight: normal;
  color: $subTitle;
  @media screen and (max-width: 500px) {
      text-align: center;
    }
}
</style>
