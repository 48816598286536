<template>
  <div id="home">
    <mainBanner />
     <events />
     <eventsMobile />
     <features />
     <customers />
     <customersMobile />
    <EventUsers />
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter";
import mainBanner from "@/components/home/mainBanner";
import events from "@/components/home/events";
import eventsMobile from "@/components/home/eventsMobile";
import features from "@/components/home/features";
import customers from "@/components/home/customers";
import customersMobile from "@/components/home/customersMobile";
import EventUsers from "@/components/event-insto-tap/EventUsers";

export default {
  name: 'Home',
  metaInfo: {
    title: 'Click, Tap, Collect 輕碰卡片、輕鬆收款',
    titleTemplate: null
  },
  components: {
    BaseFooter,
    mainBanner,
    events,
    eventsMobile,
    features, 
    customers,
    customersMobile,
    EventUsers
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#home {
  @media screen and (max-width: 900px) {
    padding-top: 60px;
  }
}
#events{
   display: block;
  @media screen and (max-width: 850px) {
    display: none;
  }
}
#events-carousel-mobile {
  display: none;
  @media screen and (max-width: 850px) {
    display: block;
  }
}
#customers {
  display: block;
  @media screen and (max-width: 1080px) {
    display: none;
  }
}
#customers-mobile {
  display: none;
  @media screen and (max-width: 1080px) {
    display: block;
  }
}

#event-users {
  margin-top: 180px !important;
}

/deep/ #event-users {
  .event-title-wrapper{
     .title-lg {
        display: none !important; 
    }
  }
  .user-name {
    display: none;
  }
}
</style>
