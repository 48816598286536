<template>
  <div id="product-more">
    <BaseTitle pageTitle="更多收款功能" pageSubtitle="More Services"></BaseTitle>
    <section class="product-container">
      <!-- sub-account -->
      <div class="product-wrapper flex-center">
         <div class="product-content">
          <div class="product-title-wrapper flex-center">
             <div class="product-icon bg icon-sub-account"></div>
            <div class="product-title flex-center">子母帳號<span>Beta</span></div>
          </div>
          <div class="content-text">
            業務體系、分店銷售都適用！指派訂單，輕鬆收款！母帳號可隨時查看子帳號收款狀況。
          </div>
          <div class="product-ta-wrapper">
            <div class="ta-label">
              適用服務：
            </div>
            <div class="ta-wrapper flex-center">
              <div class="ta-container flex-center">
                 <div class="ta-img sub-01 bg"></div>
                 <div class="ta-name">業務體系</div>
              </div>
               <div class="ta-container flex-center">
                   <div class="ta-img sub-02 bg"></div>
                   <div class="ta-name">派遣服務</div>
                </div>
                 <div class="ta-container flex-center">
                   <div class="ta-img sub-03 bg"></div>
                   <div class="ta-name">分店經營</div>
                </div>
            </div>
          </div>
        </div>
         <div class="product-image img-sub-account bg"></div> 
      </div>
      <!-- product store -->
            <div class="product-wrapper flex-center">
           <div class="product-content">
            <div class="product-title-wrapper flex-center">
               <div class="product-icon bg icon-sub-account"></div>
              <div class="product-title flex-center">我的商品</div>
            </div>
            <div class="content-text">
              預先建立訂單，一個連結，重複銷售！您可以新增固定金額之商品/服務，打造簡易線上商城！
            </div>
            <div class="product-ta-wrapper">
              <div class="ta-label">
                適用服務：
              </div>
              <div class="ta-wrapper flex-center">
                <div class="ta-container flex-center">
                   <div class="ta-img product-01 bg"></div>
                   <div class="ta-name">販售固定商品</div>
                </div>
                 <div class="ta-container flex-center">
                     <div class="ta-img product-02 bg"></div>
                     <div class="ta-name">網拍業者</div>
                  </div>
              </div>
            </div>
          </div>
           <div class="product-image img-product bg"></div> 
        </div>
        <!-- back end -->
              <div class="product-wrapper flex-center">
           <div class="product-content">
            <div class="product-title-wrapper flex-center">
               <div class="product-icon bg icon-sub-account"></div>
              <div class="product-title flex-center">賣家後台</div>
            </div>
            <div class="content-text">
              訂單管理系統，交易一目瞭然，消費明細/退款/標籤分類一應俱全，賣家後台匯出每月報表，讓你做生意沒煩惱！
            </div>
          </div>
           <div class="product-image img-back-end bg"></div> 
        </div>
    </section>
    <ServiceApply></ServiceApply>
    <BaseFooter />
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";
import ServiceApply from "@/components/ServiceApply";
import BaseFooter from "@/components/BaseFooter";

export default {
  components: {
    BaseTitle,
    ServiceApply,
    BaseFooter,
  },
  mounted() {
    var section = this.$router.currentRoute.hash.replace("#", "");
    if (section)
      this.$nextTick(() =>
        window.document.getElementById(section).scrollIntoView()
      );
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#product-more {
  font-size: 18px; 
  @media screen and (max-width: 1000px) {
    padding-top: 50px;
  }
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 540px;
  background-color: $lightBlue;
  border-radius: 0 100px 100px 100px;
  z-index: -1;
  @media screen and (max-width: 1000px) {
    border-radius: 0 50px 50px 50px;
    height: 940px;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg {
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.img-sub-account {
  background-image: url('../assets/imgs/products/more/sub-account.png');
}

.img-product {
  background-image: url('../assets/imgs/products/more/product-link.png');
  margin-right: 50px;
  width: 240px !important;
  height: 335px !important;
  @media screen and (max-width: 900px) {
    margin-right: 0px;
      }
}

.img-back-end {
  background-image: url('../assets/imgs/products/more/back-end.png');
  width: 427px !important;
  height: 265px !important;
  @media screen and (max-width: 500px) {
    width: 330px !important;
    height: 215px !important;
    }
}

.icon-sub-account{ 
  background-image: url('../assets/imgs/products/more/sub-account-icon.svg');
}

.icon-product{ 
  background-image: url('../assets/imgs/products/more/product-icon.svg');
}

.icon-back-end{ 
  background-image: url('../assets/imgs/products/more/back-end-icon.svg');
}

.sub-01 {
   background-image: url('../assets/imgs/products/more/sub-01.png');
}
.sub-02 {
   background-image: url('../assets/imgs/products/more/sub-02.png');
}
.sub-03 {
   background-image: url('../assets/imgs/products/more/sub-03.png');
}

.product-01 {
   background-image: url('../assets/imgs/products/more/product-01.png');
}

.product-02 {
   background-image: url('../assets/imgs/products/more/product-02.png');
}

.product-container {
 padding: 50px 150px;
 @media screen and (max-width: 1200px) {
    padding: 50px 50px 20px 50px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
 .product-wrapper {
   margin-bottom: 120px;
   &:last-child {
    margin-bottom: 60px;
   }
   @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 80px;
  }
    &:nth-child(even) {
        flex-direction: row-reverse;
        @media screen and (max-width: 900px) {
    flex-direction: column;
  }
      }
 }
  .product-content {
    margin-right: 50px;
    @media screen and (max-width: 900px) {
         justify-content: center;
         align-items: center;
         text-align: center;
         margin-right: 0;
         margin-bottom: 50px;
      }

    .product-title-wrapper {
      justify-content: flex-start;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
        justify-content: center;
        align-items: center;
      }

      .product-icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      .product-title {
        font-size: 30px;
        font-weight: 500;
        span {
          font-size: 12px;
          padding: 5px 11px;
          background-color: #FBCA5A;
          border-radius: 50px;
          margin-left: 6px;
          line-height: 12px;
        }
      }
    }
      .product-ta-wrapper {
        margin-top: 30px;
         @media screen and (max-width: 900px) {
         justify-content: center;
         align-items: center;
        }
        .ta-label {
          font-size: 16px;
        }
        .ta-wrapper {
          justify-content: flex-start;
          margin-top: 20px;
           @media screen and (max-width: 900px) {
            justify-content: center;
            align-items: center;
          }
          .ta-container {
            margin-right: 20px;
            flex-direction: column;
            .ta-img {
              width: 100px;
              height: 100px;
              margin-bottom: 12px;
            }
            .ta-name {
              font-size: 16px;
              color: #00C6FF;
              font-weight: 500;
            }
          }
        }
      }
    }
  .product-image {
    width: 357px;
    height: 335px;
  }
}

</style>
