<template>
   <div id="us-contact">
    <section class="contact">
      <h2>Need more help? Contact us</h2>
      <div class="mail-wrapper">
        <div class="mail-icon bg-contain"></div>
        <a href="mailto:service@ins.to" class="mail">service@ins.to</a>
      </div>
    </section>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/us-layout.scss";

  .contact {
    padding: 80px;
    background-image: linear-gradient(135deg, #00C7FF, #2581C5);
    color: white;
    text-align: center;
    @media screen and (max-width: 1024px) {
        padding: 60px 50px;
    }
    @media screen and (max-width: 768px) {
        padding: 50px 30px;
    }
    @media screen and (max-width: 500px) {
        padding: 50px 20px;
    }
    h2 {
        font-size: 24px;
        margin-bottom: 12px;
      }
      .mail-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .mail-icon {
          width: 36px;
          height: 36px;
          background-image: url('../../assets/imgs/us/icon-mail.svg');
          margin-right: 8px;
        }
      }
      a {
        font-size: 24px;
        color: white;
        &:hover {
          color: #2581C5;
        }
      }
  }

</style>
