<template>
  <div id="product-qrcode">
    <div class="background-rectangle" />
    <BaseTitle pageTitle="NFC 感應收款" pageSubtitle="INSTO Tap"></BaseTitle>
    <div class="product-wrapper">
      <div class="product-banner" />
      <h1>NFC 感應收款</h1>
      <div class="credit-card bg">
      </div>
      <h2 class="product-text">
        面對面交易，使用 Android NFC手機即可感應客人信用卡收款！<br>每筆交易付款進行3D驗證、嚴防誤刷、盜刷！
      </h2>
      <div class="product-step-wrapper">
        <div class="product-step">
          <div class="step-img bg tap-01"></div>
          <div class="step-text">店家輸入金額</div>
        </div>
        <div class="arrow bg"></div>
        <div class="product-step">
          <div class="step-img bg tap-02"></div>
          <div class="step-text">將客人的信用卡置於店家手機背面進行 NFC 感應</div>
        </div>
        <div class="arrow bg"></div>
        <div class="product-step">
          <div class="step-img bg tap-03"></div>
          <div class="step-text">感應成功，完成結帳</div>
        </div>
      </div>
    </div>
    <div class="product-ta-wrapper">
      <h1>適用服務</h1>
      <div class="ta-container">
        <div class="ta-wrapper">
          <div class="ta-img bg ta-01"></div>
          <div class="ta-text">美容美業</div>
        </div>
        <div class="ta-wrapper">
          <div class="ta-img bg ta-02"></div>
          <div class="ta-text">律師服務</div>
        </div>
        <div class="ta-wrapper">
          <div class="ta-img bg ta-03"></div>
          <div class="ta-text">計程車收款/包車旅遊</div>
        </div>
      </div>
    </div>
    <div class="product-video" style="text-align: center;" id="video-tap">
      <iframe width="100%" height="500px" class="video" src="https://www.youtube.com/embed/CwMxxZf8wIs?si=20YuswX1ZTreJuSB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
     <div class="other-scenario">
      <h1>有別種交易場景嗎？試試其他收款功能</h1>
      <div class="scenario-card-wrapper">
        <router-link to="/products/insto-me" class="scenario-qr">
          <div class="scenario-card">
            <div class="scenario-img qr"></div>
            <div class="scenario-title-wrapper">
              <div class="scenario-title">商店收款碼 & 收款立牌</div>
              <div class="blue-arrow"></div>
            </div>
            <div class="scenario-text">
              做生意太忙沒時間拿出手機嗎？出示您的專屬收款碼，<span>客人無需下載任何 App 即可掃碼付款</span>！
            </div>
          </div>
        </router-link>
        <router-link to="/products/insto-collect" class="scenario-sendbill">
          <div class="scenario-card">
            <div class="scenario-img sendbill"></div>
            <div class="scenario-title-wrapper">
              <div class="scenario-title">遠距收款</div>
              <div class="blue-arrow"></div>
            </div>
            <div class="scenario-text">
              訂單連結可以透過<span>QRcode、簡訊、Email、Line、Messenger </span>等社交軟體傳送！
            </div>
          </div>
        </router-link>
      </div>
     </div>
     <ServiceApply></ServiceApply>
     <BaseFooter />
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";
import ServiceApply from "@/components/ServiceApply";
import BaseFooter from "@/components/BaseFooter";

export default {
  components: {
    BaseTitle,
    ServiceApply,
    BaseFooter,
  },
  mounted() {
    var section = this.$router.currentRoute.hash.replace("#", "");
    if (section)
      this.$nextTick(() =>
        window.document.getElementById(section).scrollIntoView()
      );
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#product-qrcode {
  @media screen and (max-width: 1000px) {
    padding-top: 50px;
  }
}

.background-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 460px;
  background-color: $lightBlue;
  border-radius: 0 100px 100px 100px;
  z-index: -1;
  @media screen and (max-width: 1000px) {
    border-radius: 0 50px 50px 50px;
    height: 400px;
  }
}

.product-wrapper {
  padding: 50px 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1512px) {
    padding: 50px 50px;
  }
  @media screen and (max-width: 1000px) {
    padding: 50px 20px;
  }
  @media screen and (max-width: 500px) {
    padding: 0;
  }
  h1 {
    font-size: 30px;
    text-align: center;
    margin: 0;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
        margin-top: 50px;
        padding: 0 20px;
      }
  }
  .credit-card {
    margin-top: 20px;
    margin-bottom: 16px;
    width: 212px;
    height: 29px;
    background-image: url("../assets/imgs/products/card2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .product-text {
    font-size: 20px;
    line-height: 1.4;
    font-weight: normal;
    text-align: center;
    @media screen and (max-width: 768px) {
        padding: 0 20px;
      }
  }
}

.product-banner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 8px 0 $boxShadow;
  background-image: url("../assets/imgs/products/tap/banner.jpg");
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1312px) {
    height: 400px;
  }
  @media screen and (max-width: 900px) {
    height: 280px;
    border-radius: 0;
    margin-top: 20px;
  }
}

.product-step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    flex-direction: column;
  }
  .product-step {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 12px;
    width: 240px;
    @media screen and (max-width: 900px) {
      width: 240px;
      margin: 0 6px;
    }
    @media screen and (max-width: 768px) {
      margin: 0;
      margin: 12px 0;
    }
    .step-img {
      width: 105px;
      height: 105px;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        width: 150px;
        height: 150px;
      }
    }
    .tap-01 {
      background-image: url("../assets/imgs/products/tap/tap-01.png");
    }
    .tap-02 {
      background-image: url("../assets/imgs/products/tap/tap-02.png");
    }
    .tap-03 {
      background-image: url("../assets/imgs/products/tap/tap-03.png");
    }

    .step-text {
      font-size: 18px;
      line-height: 1.4;
      text-align: center;
    }
  }
}

.arrow {
  width: 13px;
  height: 28px;
  background-image: url("../assets/imgs/products/grey-arrow.svg");
  @media screen and (max-width: 768px) {
    transform: rotate(90deg);
  }
}

.bg {
  background-size: cover;
  background-position: center;
}

.product-video {
  padding: 0 50px;
  margin: 50px 0;
  @media screen and (max-width: 900px) {
    padding: 0 ;
  }
}

.video {
  border-radius: 25px;
  @media screen and (max-width: 900px) {
    border-radius: 0;
  }
}

.product-ta-wrapper {
  width: 100%;
  padding: 50px;
  background-color: #F6FAFB;
  @media screen and (max-width: 768px) {
    padding: 50px 20px;
  }
  h1 {
    margin-bottom: 20px;
    font-weight: normal;
    text-align: center;
  }
  .ta-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        display: inline-block;
      }
    .ta-wrapper {
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
    .ta-img {
      width: 250px;
      height: 250px;
      border-radius: 12px;
      @media screen and (max-width: 768px) {
        border-radius: 0;
        width: 100%;
        height: 220px;
      }
      @media screen and (max-width: 500px) {
        height: 180px;
      }
    }
    .ta-text {
      margin-top: 12px;
      font-size: 20px;
      text-align: center;
    }

    .ta-01 {
      background-image: url("../assets/imgs/products/tap/ta-01.jpg");
    }
    .ta-02 {
      background-image: url("../assets/imgs/products/tap/ta-02.jpeg");
    }
    .ta-03 {
      background-image: url("../assets/imgs/products/tap/ta-03.jpg");
    }
  }
}

.other-scenario {
    padding: 50px;
    text-align: center;
    background-color: #F6FAFB;
    margin-bottom: 50px;
    h1 {
      font-weight: normal;
    }
    @media screen and (max-width: 768px) {
      padding: 50px 20px;
    }
  .scenario-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        flex-direction: column;
      }

    a {
      margin-right: 20px;
      transition: all ease-in-out .3s;
      &:last-child {
        margin-right: 0;
        }
      &:hover { 
        color:#0D8AED;
        transform: translateY(4px);
        .blue-arrow {
          transform: translateX(4px);
        }
      }
      @media screen and (max-width: 768px) {
        margin-right: 0px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }

    }
  }

  .scenario-card {
    max-width: 300px;
    border-radius: 12px;
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 30px;
    }
  }
  .scenario-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: contain;
    margin-bottom: 30px;
    background-repeat: no-repeat;
  }
  .scenario-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .tap{
    background-image: url("../assets/imgs/products/scene-tap.png");
  }
  .qr{
    background-image: url("../assets/imgs/products/scene-qr.png");
  }

  .sendbill {
    background-image: url("../assets/imgs/products/scene-sendbill.png");
  }

  .blue-arrow {
    width: 10px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/imgs/products/blue-arrow.svg");
    margin-left: 4px;
    transition: all ease-in-out .3s;
  }
  .scenario-text {
    text-align: left;
    font-size: 15px;
    span {
      color: #0D8AED;
    }
  }
}



</style>
