<template>
  <div id="events-carousel-mobile">
    <div class="event-wrapper">
      <carousel
        paginationActiveColor="#00c8ff"
        :perPage="1"
        autoplay
        :autoplayTimeout="2500"
        easing="ease"
        loop
      >
      <slide id="event-m-ap">
          <div class="slide-wrapper">
            <router-link to="/event/applepay">
              <div class="event applepay">
              </div>
            </router-link>
          </div>
      </slide>
      <!-- <slide>
          <div class="slide-wrapper">
            <router-link to="/pricing">
              <div class="event pricing">
              </div>
            </router-link>
          </div>
      </slide> -->
       <slide id="event-m-ita">
          <div class="slide-wrapper">
            <router-link to="/event/insto-tap-app" target="_blank">
              <div class="event insto-tap-app">
              </div >
            </router-link>
          </div>
        </slide>
        <slide id="event-m-nfc-video">
          <div class="slide-wrapper">
             <a href="https://youtu.be/CwMxxZf8wIs?si=LID0OkGdmLqJLnvA" target="_blank">
              <div class="event video">
              </div >
            </a>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#events-carousel-mobile {
  margin: 20px 0 20px 0;
  padding: 0 20px;
  @media screen and (max-width: 320px) {
      margin: 0;
      paddgin: 0;
    }
}

/deep/ {
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin-top: 0 !important;
      button.VueCarousel-dot {
        padding: 0 !important;
        width: 30px !important;
        height: 5px !important;
        border-radius: 100px !important;
        background-clip: unset !important;
        box-sizing: unset !important;
        margin: 0 3px;
        outline: none !important;
      }
    }
  }
}

.slide-wrapper {
  background: transparent;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 220px;
   @media screen and (max-width: 320px) {
       height: 180px;
    }
}


.event {
  border-radius: 20px;
  width: 391px;
  height: 220px;
  background-color: white;
  background-size: cover;
  @media screen and (max-width: 600px) {
    width: 320px;
    height: 180px;
  }
   @media screen and (max-width: 320px) {
       border-radius: 0;
    }
}
.video {
  background-image: url('../../assets/imgs/home/03.png');
}
.pricing {
  background-image: url('../../assets/imgs/home/01.png');
}
.insto-tap-app {
  background-image: url('../../assets/imgs/home/02.png');
}
.applepay {
  background-image: url('../../assets/imgs/home/04.png');
}
</style>
