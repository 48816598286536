<template>
  <div id="us-home">
    <div class="home-wrapper">
      <div class="home-top">
        <router-link to="/us-home">
          <img src="../assets/imgs/tap-logo.svg" height="30" alt="logo" class="logo" />
        </router-link>
        <div class="lang-selector">
          <img src="../assets/imgs/us/lang.svg" height="16" alt="lang">
          <div class="lang-text">
            English-EN
          </div>
        </div>
      </div>
      <div class="content-container">
      <div class="content-wrapper">
        <router-link to="/us/home">
        <img src="../assets/imgs/tap-logo.svg" height="40" alt="logo" />
        </router-link>
        <h1>Click, Tap, Collect.</h1>
        <h2>Best solution for all businesses!</h2>
        <p>Tired of costly credit card readers with limited features?<br>
          Switch to INSTO Tap! Turn your phone into a reader, sell, and collect payments anytime, anywhere!
        </p>
        <div class="btns">
          <router-link to="/us/individual" class="btn">
            Individual Seller
          </router-link>
          <router-link to="/us/business" class="btn">
            Business Seller
          </router-link>
      </div>
      <div class="downloads">
        <div class="app-logo bg-contain"></div>
        <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener">
          <div class="ios-logo bg-contain"></div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank" rel="noopener">
          <div class="android-logo bg-contain"></div>
        </a>
      </div>
      <h3>copyright © 2024 INSTO, LLC.</h3>
      </div>
      <div class="phone-img bg-contain"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

  export default {
    name: 'US-Home',
    metaInfo: {
      title: 'Click, Tap, Collect',
      titleTemplate: 'INSTO Tap US | %s ',
      htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      {
        property: 'og:title',
        content: 'Click, Tap, Collect',
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: 'Best solution for all businesses!',
        vmid: 'og:description'
      },
    ]
  },
    components: {
    }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";

  #us-home {
    margin-left: 0;
    width: 100%;
    height: 100vh;
    cursor: default;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #F2FCFF;
    background-image: url('../assets/imgs/us/bg-1920.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    @media screen and (max-width: 1024px) {
      background-size: contain;
      background-position: center center;
      height: 100%;
    }

  }


  .home-wrapper {
    width: 100%;
    padding: 100px 80px;
    max-width: 1200px;
    @media screen and (max-width: 1024px) {
      padding: 20px 30px 20px 30px;
    }
    @media screen and (max-width: 500px) {
      padding: 0 20px 20px 20px;
    }

    .home-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .logo{
        display: none;
      }
      .lang-selector {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        border-radius: 50px;
        border: 1px solid #4a4a4a;
        .lang-text {
          margin-left: 4px;
        }
      }
      @media screen and (max-width: 768px) {
        height: 70px;
        img {
          display: block;
        }
      }
    }

    .content-container {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media screen and (max-width: 768px) {
      width: 100%;
        img {
          display: none;
        }
        margin-top: -30px;
      }
    }

    h1 {
      font-size: 32px;
      line-height: 32px;
      margin:0;
      margin-bottom: 12px;
      margin-top: 30px;
    }

    h2 {
      font-size: 24px;
      line-height: 24px;
      margin:0;
      margin-bottom: 16px;
      font-weight: normal;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
      max-width: 400px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    h3 {
      font-weight: normal;
      margin:0;
      font-size: 12px;
      margin-top: 30px;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        margin-top: 0;
      }
    }
  }

  .phone-img {
    width: 500px;
    height: 500px;
    background-image: url('../assets/imgs/us/app-us.png');
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 400px;
      margin: 0 auto;
      margin-right: -40px 
    }
    @media screen and (max-width: 500px) {
      width: 110%;
      height: 500px;
      margin-right: -80px 
    }
    @media screen and (max-width: 400px) {
      width: 120%;
      height: 430px;
      margin-right: -100px 
    }
  }

  .bg-img {
    background-image: url('../assets/imgs/us/home-bg.png');
  }

  .btns {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      justify-content: center;
      width: 100%;
      }
    .btn {
      padding: 12px 20px;
      font-size: 16px;
      color: white;
      background-image: linear-gradient(135deg, #00C7FF, #2581C5);
      border-radius: 50px;
      transition: .3s all ease-in-out;
      &:hover {
        transform: translateX(6px);
        background-color: #2581C5;
      }
      &:last-child {
        margin-left: 12px;
        background-image: linear-gradient(135deg, #5cb9b0,#0d9494);
        &:hover {
          background-color: #15A5A6;
        }
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .downloads {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 30px 0;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  .app-logo {
    height: 39px;
    width: 39px;
    border-radius: 11px;
    box-shadow: 0 3px 6px rgba(13, 138, 273, 0.2);
    margin-right: 12px;
    background-image: url("../assets/imgs/app-logo.png");
    @media screen and (max-width: 320px) {
      margin-right: 12px;
      margin-bottom: 0;
      height: 30px;
      width: 30px;
    } 
  }

  .android-logo {
    height: 39px;
    width: 126px;
    background-image: url("../assets/imgs/download-google-play.png");
    @media screen and (max-width: 320px) {
      height: 30px;
      width: 97px;
    } 
  }
}

.ios-logo {
    height: 39px;
    width: 126px;
    margin-right: 12px;
    background-image: url("../assets/imgs/download-app-store.png");
     @media screen and (max-width: 320px) {
      margin-right: 12px;
      margin-bottom: 0;
      height: 30px;
      width: 97px;
    } 
  }


  .bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bg-contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

</style>
