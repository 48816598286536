<template>
  <div id="pricing-faq">
    <h1>常見問題</h1>
    <div class="faq-list" v-for="(item, index) in items" :key="index" @click="toggleAccordion(index)" :id="'pricing-faq-q'+index">
      <div class="faq-title-wrapper accordion" :id="'pricing-faq-q'+index">
        <div class="title-md">{{ item.title }}</div>
        <div class="accordion-icon bg-contain" :class="{ 'icon-active': activeAccordion === index }"></div>
      </div>
      <div class="panel" :key="index" :class="{ 'active': activeAccordion === index }">
          {{ item.content }}
        <div v-html="item.url" style="display:inline"></div>
        <img :src="item.img">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "如何申請成為 INSTO Tap 賣家？", content: "我們採全線上申請制！請在手機下載 INSTO Tap App > 完成註冊 > 就可以開始申請賣家囉！" },
        { title: "使用 INSTO Tap 會有任何額外的費用嗎？", content: "沒有任何隱藏額外費用，我們僅收取成交交易手續費！讓你輕輕鬆鬆入門擁有方便的收款工具。" },
        { title: "我想收國外卡，如何申請開通呢？", content: "在您初次提交賣家申請時，於最後一個步驟即可一併申請國外卡收款，方便又快速！或你也能在 App 首頁中間橫幅點選開通；或在 App 側邊選單>我的帳戶>收款方式>進行國外卡申請。*國外卡申辦需等候數個工作天審閱，審閱通過後會收到email開通繳費單，完成繳費後即可開通哦！" ,url:'<a href="https://support.ins.to/hc/zh-tw/articles/4408209327641" target="_blank" style="color:#0986F9; text-decoration:underline">詳細可參閱</a>'},
        { title: "刷卡完成後，多久能拿到錢？", content: "系統將於每週四，自動撥款8天前～14天前之款項（一～兩週前），且將於完成撥款後發送 App 推播 及 E-mail通知。",img:require('../assets/imgs/event/foreign-card/withdrawal-image.png') },
        { title: "每月收款額度可以調整嗎？", content: "企業賣家可以申請調整額度，申請方式",url:'<a href="https://support.ins.to/hc/zh-tw/articles/4408461407513" target="_blank" style="color:#0986F9; text-decoration:underline">詳細可參閱</a>' },
        { title: "有限制哪家銀行才能當撥款帳戶嗎？", content: "沒有哦！國內任何一間銀行、郵局、農漁會等銀行，都能作為INSTO的撥款帳戶！" },
        { title: "撥款有轉匯費嗎？", content: "如當週撥款金額大於新台幣3,000元，則無須轉匯費哦！",url:'<a href="https://support.ins.to/hc/zh-tw/articles/4405894854937" target="_blank" style="color:#0986F9; text-decoration:underline">詳細可參閱</a>' },
        { title: "除了信用卡，客人可以使用 Apple Pay 付款嗎？需要額外申辦嗎？", content: "只要申辦INSTO Tap，就可以享有Apple Pay 的功能，不需額外付費申辦哦！而您的客人使用的iPhone、iOS 系列產品中有綁定 Apple Pay，在他點開 INSTO 付款連結、或掃描 Qrcode 時，結帳畫面就會出現 Apple Pay 付款的按鈕選項，點擊該選項後即可進行 Apple Pay 支付款項囉！",img:require('../assets/imgs/event/insto-tap-app/ap-btn-example.png') }
      ],
      activeAccordion: null
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/foreign-credit-card.scss";

#pricing-faq {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1552px) {
    padding: 60px 100px 80px 100px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px 50px 80px 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 30px 80px 30px;
  }

  h1 {
    margin: 0;
    font-size: 30px;
  }

  .faq-list {
    width: 100%;
    border-bottom: 1px solid #b9b9b9;
    padding: 12px 0;
    color: #464a53;

    a {
      color: #464a53;
    }

    .title-md {
      padding: 0;
      font-weight: normal !important;
      font-size: 20px;
    }

    img {
      display: block;
      width: 50%;
      margin: 10px auto;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  .accordion {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.accordion-icon {
  cursor: pointer;
  background-image: url('../assets/imgs/event/foreign-card/trigger-icon.svg');
  width: 36px;
  height: 36px;
  margin-left: 20px;
  transition: all .3s ease-in-out;
  flex-shrink: 0;
  &:hover {
    transform: rotate(45deg);
  }
}

.panel {
  text-align: left;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 16px;
  line-height: 1.5;
}

.active {
  max-height: 100%;
}

.icon-active {
  transform: rotate(45deg);
}

.arrow-icon {
  background-image: url('../assets/imgs/event/foreign-card/arrow.svg');
  width: 36px;
  height: 36px;
  margin-left: 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover {
    transform: translateX(-4px);
  }
}

</style>
