<template>
  <div id="ap-event-main">
    <div class="main-container bg-cover">
      <router-link to="/">
        <img src="../../assets/imgs/event/apple-pay/tap-logo.png" alt="logo" class="logo"/>
      </router-link>
      <div class="ap-circle-bg bg-contain"></div>
      <div class="main-text">
        <h1 class="title-lg">免申請、免付費<br>
        使用 Apple Pay 收款<br>
        超・簡・單
      </h1>
      <button class="action-button" v-on:click="download()" id="ap-main-acbtn">
        立即註冊
      </button>
      <div class="downloads">
        <div class="app-logo bg-contain"></div>
        <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener" id="ap-main-iosbtn">
          <div class="ios-logo bg-contain"></div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank" rel="noopener"
          id="ap-main-androidbtn">
          <div class="android-logo bg-contain"></div>
        </a>
      </div>
      </div>
    </div>
    <div class="ap-phone bg-contain"></div>
    <div class="ap-pad bg-contain"></div>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('ap-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/apple-pay.scss";

#ap-event-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
  .main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 740px;
    border: 10px solid #34DC60;
    background-image: url('../../assets/imgs/event/apple-pay/ap-main-bg.png');
    text-shadow: rgba(0, 6, 93, 0.50) 1px 1px 16px;
    overflow: hidden;
    @media screen and (max-width: 515px) {
      height: 600px;
    }
  }

.logo {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translate(-50%);
  height: 55px;
   z-index: 998;
  @media screen and (max-width: 768px) {
      top: 30px;
      height: 40px;
    }
  }

  .action-button {
    font-size: 30px;
    margin-top: 30px;
    width: 400px;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 24px;
     }
    }

  .ap-phone {
    position: absolute;
    left: 150px;
    width: 800px;
    height: 764px;
    background-image: url('../../assets/imgs/event/apple-pay/ap-phone.png');
    animation: floating infinite 2.5s ease-in-out ;
    z-index: 997;
    @media screen and (max-width: 1440px) {
      left: 0;
      width: 390px;
      height: 540px;
    }
    @media screen and (max-width: 768px) {
      top: 40px;
      width: 300px;
      height: 360px;
    }
    @media screen and (max-width: 515px) {
      top: 70px;
      width: 220px;
      height: 220px;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);

    }
    50% {
      transform: translateY(8px);
    }
    100%{
      transform: translateY(0px);
    }
  }

  .ap-pad {
    position: absolute;
    right: 50px;
    width: 800px;
    height: 800px;
    background-image: url('../../assets/imgs/event/apple-pay/ap-pad.png');
    animation: floating-pad infinite 2.5s ease-in-out ;
    z-index: 997;
    @media screen and (max-width: 1440px) {
      right: -10%;
      width: 600px;
      height: 600px;
    }
    @media screen and (max-width: 768px) {
      top: 40px;
      width: 400px;
      height: 400px;
    }
    @media screen and (max-width: 515px) {
      right: 2px;
      top: 70px;
      width: 250px;
      height: 250px;
    }
  }

  @keyframes floating-pad {
    0% {
      transform: translateY(8px);

    }
    50% {
      transform: translate3d(10px, 0, 100px);
    }
    100%{
      transform: translateY(8px);
    }
  }

  .ap-circle-bg {
    position: absolute;
    z-index: 1;
    width: 650px;
    height: 650px;
    background-image: url('../../assets/imgs/event/apple-pay/main-blue-circle.png');
    @media screen and (max-width: 768px) {
      background-image: none;
    }
  }

  .main-text {
    position: absolute;
    z-index: 998;
    @media screen and (max-width: 768px) {
      bottom: 23px;
    }
    @media screen and (max-width: 515px) {
      bottom: -10px;
      .title-lg {
        font-size: 32px;
      }
    }
  }

  .downloads {
    @media screen and (max-width: 515px) {
      scale: 0.8;
      margin-top: 20px;
    }
  }

  .app-logo {
    background-image: url("../../assets/imgs/app-logo.png");
  }

  .android-logo {
    background-image: url("../../assets/imgs/download-google-play.png");
    }

  .ios-logo {
    background-image: url("../../assets/imgs/download-app-store.png");
  }

}

</style>
