<template>
  <div id="ap-event-apply">
    <h1 class="title-lg">輕鬆申請4步驟</h1>
    <div class="apply-steps-wrapper">
      <div class="step-wrapper">
        <div class="step-img bg-contain step01"></div>
        <div class="step-title">下載App</div>
      </div>
      <div class="step-wrapper">
          <div class="step-img bg-contain step02"></div>
          <div class="step-title">填寫資料</div>
      </div>
        <div class="step-wrapper">
        <div class="step-img bg-contain step03"></div>
        <div class="step-title">送出審核</div>
      </div>
        <div class="step-wrapper">
        <div class="step-img bg-contain step04"></div>
        <div class="step-title">審核成功</div>
      </div>
    </div>
    <div class="action-button-wrapper">
      <a href="https://tap.ins.to/products/apply-example-personal" target="_blank" id="ap-apply-personal-exurl">
        <button class="action-button outline-style">
          申請個人賣家範例
        </button>
      </a>
      <a href="https://tap.ins.to/products/apply-example-business" target="_blank" id="ap-apply-business-exurl">
        <button class="action-button outline-style">
          申請企業賣家範例
        </button>
      </a>
      </div>
       <button class="action-button outline-style sm-size" v-on:click="download()" id="ap-apply-acbtn">
        成為 INSTO 賣家，享受 ApplePay 收款
      </button>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('ap-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/apple-pay.scss";

#ap-event-apply {
  margin: 0 auto;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  @media screen and (max-width: 1552px) {
    padding: 60px 100px;
  }
  @media screen and (max-width: 1024px) {
    padding: 50px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
  .apply-steps-wrapper { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
      width: 800px;
    }
    @media screen and (max-width: 768px) {
      width: 400px;
    }
    
    .step-wrapper {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       margin-top: 50px;
       .step-img {
        height: 150px;
        width: 150px;
        margin: 0 10px;
       }
       .step-title {
        margin-top: 12px;
        font-size: 30px;
        @media screen and (max-width: 1440px) {
          font-size: 24px;
        }
       }
    }
  }
}

.step01 {
  background-image: url('../../assets/imgs/event/apple-pay/ap-apply-step01.png');
}

.step02 {
  background-image: url('../../assets/imgs/event/apple-pay/ap-apply-step02.png');
}

.step03 {
  background-image: url('../../assets/imgs/event/apple-pay/ap-apply-step03.png');
}

.step04 {
  background-image: url('../../assets/imgs/event/apple-pay/ap-apply-step04.png');
}

.action-button-wrapper {
  margin-bottom: 10px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.sm-size {
  @media screen and (max-width: 492px) {
    font-size: 17px;
    height: 60px;
  }
}


</style>
