<template>
  <div id="ap-event-faq">
    <h1 class="title-md">常見問題</h1>
    <div class="faq-list" v-for="(item, index) in items" :key="index" @click="toggleAccordion(index)" :id="'ap-faq-q'+index">
      <div class="faq-title-wrapper accordion" :id="'ap-faq-q'+index">
        <div class="title-md">{{ item.title }}</div>
        <div class="accordion-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="37.4" height="37.4" xml:space="preserve">
          <path d="M.7 18.7h18m0 0h18m-18 0V.7m0 18v18" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>
      </div>
      <div class="panel" :key="index" :class="{ 'active': activeAccordion === index }">
          {{ item.content }}
        <div v-html="item.url" style="display:inline"></div>
        <img :src="item.img">
      </div>
    </div>
    <a href="https://support.ins.to/hc/zh-tw" target="_blank" style="width:100%;" id="ap-faq-url">
      <button class="action-button">
        更多問題請點我 Go
      </button>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "客人如何使用 Apple Pay 付款？", content: "只要客人的 iPhone、iOS 系列產品中有綁定 Apple Pay，在他點開 INSTO 付款連結、或掃描 Qrcode 時，結帳畫面就會出現 Apple Pay 付款的按鈕選項，點擊該選項後即可進行 Apple Pay 支付款項囉！",img:require('../../assets/imgs/event/apple-pay/ap-btn-example.png') },
        { title: "如何申請？", content: "我們採全線上申請制！請在手機下載 INSTO Tap App > 完成註冊 > 就可以開始申請賣家囉！" },
        { title: "有任何額外的費用嗎？", content: "沒有任何隱藏額外費用，我們僅收取成交交易手續費！讓你輕輕鬆鬆入門擁有方便的收款工具。" },
        { title: "有限制哪家銀行才能當撥款帳戶嗎？", content: "沒有哦！國內任何一間銀行、郵局、農漁會等銀行，都能作為 INSTO 的撥款帳戶！" },
        { title: "刷卡完成後，多久能拿到錢？", content: "系統將於每週四，自動撥款8天前～14天前之款項（一～兩週前），且將於完成撥款後發送 App 推播 及 E-mail 通知。",img:require('../../assets/imgs/event/apple-pay/withdrawal-image.png') },
        { title: "收款有多少交易使用額度呢？", content: "個人賣家每月有15萬的交易額度；企業賣家每月有80萬的交易額度。",url:'<a href="https://tap.ins.to/pricing" target="_blank" style="color:#fff; text-decoration:underline">詳細可參閱</a>'},
      ],
      activeAccordion: null
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
   @import "./src/assets/sass/apple-pay.scss";

#ap-event-faq {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1552px) {
    padding: 60px 100px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }

  h1 {
    margin: 0;
  }

  .faq-list {
    width: 100%;
    border-bottom: 1px solid white;
    padding: 12px 0;
    color: white;
    cursor: pointer;

    a {
      color: white;
    }

    .title-md {
      padding: 0;
      font-weight: normal !important;
    }

    img {
      display: block;
      width: 50%;
      margin: 10px auto;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  .accordion {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.accordion-icon {
  cursor: pointer;
  margin-left: 20px;
  transition: all .3s ease-in-out;
  flex-shrink: 0;
}

.panel {
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 18px;
  line-height: 1.5;
}

.active {
  max-height: 100%;
}

.active,
.accordion:hover {
  font-weight: blod;

  .accordion-icon {
    transform: rotate(45deg);
  }
}

.action-button {
  margin-top: 50px;
}

</style>
