<template>
  <div id="features">
    <h1 class="feature-title">INSTO Tap 操作簡單易上手<br>客人免下載額外App，輕鬆享受便捷消費體驗</h1>
    <div class="mpos">
      <div class="feature-text">
        <h1>你的行動收款App</h1>
        <div class="card-wrapper">
            <div class="card">
              <img src="../../assets/imgs/home/visa.png" alt="visa" height="35px">
            </div>
            <div class="card">
              <img src="../../assets/imgs/home/master.png" alt="master" height="35px">
            </div>
            <div class="card">
              <img src="../../assets/imgs/home/jcb.png" alt="jcb" height="35px">
            </div>
            <div class="card">
              <img src="../../assets/imgs/home/applepay.svg" alt="apple pay" height="35px">
            </div>
        </div>
        <div class="feature-btns-wrapper">
          <router-link to="/products/insto-tap" class="feature-button" id="home-f-nfc">感應收款</router-link>
          <router-link to="/products/insto-me" class="feature-button" id="home-f-qr">商店收款碼 & 收款立牌</router-link>
          <router-link to="/products/insto-collect" class="feature-button" id="home-f-send">遠距收款</router-link>
          <router-link to="/products/more-services" class="feature-button" id="home-f-more">更多功能</router-link>
          </div>
      </div>
      <div class="feature-image-mpos">
        <video loop autoplay muted playsinline
          class="phone"
        >
        <source  src="../../assets/imgs/home/app-flow.webm" type="video/webm">
        <source  src="../../assets/imgs/home/app-flow.mp4" type="video/mp4">
        </video>
        <img
          src="../../assets/imgs/home/credit.png"
          alt="insto robot"
          class="robot-credit-card"
        />
      </div>
    </div>
    <div class="ta">
      <div class="ta-wrapper">
        <div class="ta-img law"></div>
        <div class="ta-title">律師服務</div>
      </div>
      <div class="ta-wrapper">
        <div class="ta-img beauty"></div>
        <div class="ta-title">美容美業</div>
      </div>
      <div class="ta-wrapper">
        <div class="ta-img vendor"></div>
        <div class="ta-title">市集攤商</div>
      </div>
      <div class="ta-wrapper">
        <div class="ta-img shop"></div>
        <div class="ta-title">代購服務</div>
      </div>
      <div class="ta-wrapper">
        <div class="ta-img handmade"></div>
        <div class="ta-title">商品訂製</div>
      </div>
      <div class="ta-wrapper">
        <div class="ta-img cooperate"></div>
        <div class="ta-title">訂金</div>
      </div>
    </div>
    <div class="compare">
      <h1>總是有更好的選擇</h1>
      <div class="title-wrapper">
        <div class="table-title"></div>
        <div class="table-title">傳統刷卡機/其他支付業者</div>
        <div class="table-title">INSTO Tap</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">月費、年費</div>
        <div class="table-text others">需收費</div>
        <div class="table-text insto">免費</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">安裝費、租賃費</div>
        <div class="table-text others">需收費</div>
        <div class="table-text insto">免費</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">押金</div>
        <div class="table-text others">視行業別而定</div>
        <div class="table-text insto">免費</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">Apple Pay</div>
        <div class="table-text others">會員年費制</div>
        <div class="table-text insto">免費</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">國外卡</div>
        <div class="table-text others">會員年費制</div>
        <div class="table-text insto">一次性設定費</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">收款途徑</div>
        <div class="table-text others">面對面交易</div>
        <div class="table-text insto">遠距/當面皆可</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">申辦方法</div>
        <div class="table-text others">紙本/業者接洽</div>
        <div class="table-text insto">5分鐘完成線上申辦</div>
      </div>
      <div class="table-wrapper">
        <div class="table-text label">客服管道</div>
        <div class="table-text others">聯繫管道不透明</div>
        <div class="table-text insto">真人即時線上客服</div>
      </div>
    </div>
    <!-- <div class="pricing">
      <div class="feature-image-pricing">
        <img
          src="../../assets/imgs/home/collectmoney.png"
          alt="insto tap pricing"
        />
      </div>
      <div class="feature-text">
        <h1>
          零月費、
          <br />更低的手續費、
          <br />更好的選擇
        </h1>
        <h2>
          零月費，絕無隱藏費用與任何押金，下載、註冊、申請都免費！僅需支付單筆交易手續費用。
        </h2>
        <router-link to="/pricing" class="button" id="home-f-pricing">計費方案</router-link>
      </div>
    </div> -->
    <div class="secure">
      <h1>安全的交易環境</h1>
      <div class="secure-wrapper">
        <div class="secure-check"></div>
        <h2>SSL 加密、PCI DSS 支付卡產業資料安全標準規範</h2>
      </div>
      <div class="secure-wrapper">
        <div class="secure-check"></div>
        <h2>交易啟用3D驗證，以防盜刷風險</h2>
      </div>
      <div class="secure-wrapper">
        <div class="secure-check"></div>
        <h2>帳號閒置15分鐘後自動登出，確保交易安全</h2>
      </div>
    </div>
    <div class="seller">
      <h1>賣家線上申請、免紙本、省時便利</h1>
      <h2>
        申請賣家審核時間最少需7個⼯作天（不含例假⽇、國定假⽇）
      </h2>
      <div class="seller-apply-wrapper">
      <div class="seller-wrapper">
        <div class="seller-container">
          <div class="seller-bg business"></div>
          <div class="seller-title">企業賣家</div>
          <div class="seller-subtitle">適合公司行號、有統一編號之商家</div>
          <div class="seller-service-wrapper">
            <div class="seller-service">
              <div class="service-check"></div>
              <div class="service-title">商業/公司登記證明文件</div>
            </div>
            <div class="seller-service">
              <div class="service-check"></div>
              <div class="service-title">企業代表人身分證字號</div>
            </div>
            <div class="seller-service">
              <div class="service-check"></div>
              <div class="service-title">商店/商品資訊</div>
            </div>
            <div class="seller-service">
              <div class="service-check"></div>
              <div class="service-title">收款帳戶（帳戶存摺封面）</div>
            </div>
          </div>
           <router-link to="/products/apply-example-business"
            class="example-btn" id="home-f-ex-business">我需要準備什麼？查看申請範例</router-link>
        </div>
        <div class="seller-container-wrapper">
          <div class="seller-container">
            <div class="seller-bg personal"></div>
            <div class="seller-title">個人賣家</div>
            <div class="seller-subtitle">適合一般個人、無統一編號之商家</div>
            <div class="seller-service-wrapper">
              <div class="seller-service">
                <div class="service-check"></div>
                <div class="service-title">手機驗證</div>
              </div>
              <div class="seller-service">
                <div class="service-check"></div>
                <div class="service-title">身分證正反面</div>
              </div>
              <div class="seller-service">
                <div class="service-check"></div>
                <div class="service-title">商店/商品資訊</div>
              </div>
              <div class="seller-service">
                <div class="service-check"></div>
                <div class="service-title">收款帳戶（帳戶存摺封面）</div>
              </div>
            </div>
            <router-link to="/products/apply-example-personal"
            class="example-btn" id="home-f-ex-personal">我需要準備什麼？查看申請範例</router-link>
          </div>
        </div>
      </div>
    </div>
      <div class="btns-wrapper">
        <router-link to="/products/apply-example-business" class="button" id="home-f-btn-ex-business">申請企業賣家</router-link>
        <router-link to="/products/apply-example-personal" class="button" id="home-f-btn-ex-personal">申請個人賣家</router-link>
      </div>
      <a href="https://support.ins.to/hc/zh-tw/articles/900004310046-個人賣家申請-Apply-for-Individual-Seller-" target="_blank">點我看申請教學</a>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#features {
  padding: 0px 50px;
  @media screen and (max-width: 900px) {
    padding: 0px 20px;
    margin: 30px 0;
  }
  @media screen and (max-width: 500px) {
    padding: 0px 10px;
  }
}

video::-webkit-media-controls {
  display: none;
}

.feature-title {
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}

h1,
h2 {
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 18px;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}
h1 {
  font-weight: 500;
  font-size: 40px;
  @media screen and (max-width: 1006px) {
    font-size: 36px;
  }
  @media screen and (max-width: 500px) {
    font-size: 32px;
  }
}

.pricing {
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse !important;
  }
}

.mpos,
.pricing {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 30px 0;
  }
  .feature-text {
    max-width: 315px;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
    h1 {
      margin-bottom: 20px;
    }
    h2 {
      line-height: 24px;
      margin-bottom: 30px;
      @media screen and (max-width: 500px) {
         line-height: 22px;
      }
    }
  }
}

.robot-credit-card {
  position: absolute;
  top: 6px;
  right: -149px;
  z-index: -1;
  animation-name: robot-pay;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media screen and (max-width: 1006px) {
    right: -119px;
  }
  @media screen and (max-width: 500px) {
    right: -79px;
  }
}

@keyframes robot-pay {
  50%,
  100% {
    opacity: 0;
    top: 310px;
  }
  0% {
    opacity: 0;
  }
  20% {
    top: 6px;
    opacity: 1;
  }
  45% {
    top: 310px;
    opacity: 1;
  }
}

.feature-btns-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }
}

.feature-button {
  text-align: center;
  padding: 10px 18px;
  width: 220px;
  font-size: 18px;
  border: 1px solid #00c8ff;
  margin-bottom: 12px;
  border-radius: 30px;
  transition: all .3s ease-in-out;
  &:hover {
    transform: translateX(12px);
    color: white;
    background: #00c8ff;
  }
}

.feature-image-mpos {
  position: relative;
  .phone {
    position: relative;
    width: 200px;
    border: 7px solid #424242;
    border-radius: 20px;
    margin-left: 80px;
    @media screen and (max-width: 1006px) {
      width: 180px;
      margin-left: 20px;
    }
    @media screen and (max-width: 900px) {
      margin-left: 0;
      margin-top: 40px;
    }
    @media screen and (max-width: 500px) {
      border: 6px solid #424242;
    }
  }
  img {
    width: 150px;
    height: auto;
    @media screen and (max-width: 1006px) {
      width: 120px;
    }
    @media screen and (max-width: 500px) {
      width: 80px;
    }
  }
}

.feature-image-pricing {
  width: 260px;
  margin-right: 80px;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1006px) {
    width: 240px;
  }
  @media screen and (max-width: 900px) {
    width: 220px;
    margin-right: 0;
    margin-top: 40px;
  }
}

.seller, .ta, .comapre, .secure {
  padding: 60px 0;
  text-align: center;
  h1 {
    margin-bottom: 24px;
  }

  h2 {
    margin-bottom: 30px;
    line-height: 24px;
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
}

.seller h2 {
  margin-bottom: 15px;
}

#seller-notice {
  justify-content: center;
  margin-bottom: 30px;
}

.secure-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 12px;
  h2 {
    margin: 0;
  }
  .secure-check {
    flex-basis: 14px;
    width: 14px;
    height: 9px;
    background-image: url("../../assets/imgs/home/check.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 8px;
    margin-top: 8px;
  }
}

.btns-wrapper {
  margin: 20px auto;
  @media screen and (max-width: 410px) {
      .button {
        margin-top: 12px;
      }
    }
}

a {
  color: $mainBlue;
}

.seller-wrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 500px) {
    padding: 0 20px;
  }
  .seller-container {
    width: 360px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 $boxShadow;
    background-color: white;
    padding: 30px;
    margin-right: 20px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 800px) {
      margin-right: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .seller-container-wrapper {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .seller-bg {
    width: 100px;
    height: 141px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @media screen and (max-width: 500px) {
      width: 63px;
      height: 90px;
    }
    @media screen and (max-width: 320px) {
      width: 58px;
      height: 80px;
    }
  }

  .business {
    background-image: url("../../assets/imgs/products/business-seller.png");
  }
  .personal {
    background-image: url("../../assets/imgs/products/personal-seller.png");
  }

  .seller-title {
    font-size: 20px;
    text-align: left;
  }

  .seller-subtitle {
    margin-top: 8px;
    color: $subTitle;
    font-size: 13px;
    text-align: left;
  }

  .seller-service-wrapper {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .seller-service {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .service-check {
    margin-right: 16px;
    background-image: url("../../assets/imgs/pricing/service-check.svg");
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
  }
}

.card-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
   @media screen and (max-width: 600px) {
    justify-content: center;
  }
}

.card {
  margin: 0 12px;
   @media screen and (max-width: 600px) {
    margin: 20px 0;
  }
  img {
    height: 26px;
    width: auto;
  }
}

.ta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 0 10px;
  }
  .ta-wrapper {
    display: block;
    margin: 10px;
    .ta-img  {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      background-size: cover;
      background-color: #00c8ff;
      @media screen and (max-width: 900px) {
        width: 150px;
        height: 150px;
    }
      @media screen and (max-width: 768px) {
        width: 120px;
      height: 120px;
      border-radius: 50%;
    }
    }
    .ta-title {
      font-size: 17px;
      margin: 12px auto;
    }
    .law {
      background-image: url('../../assets/imgs/home/ta/ta-lawyer.jpg');
    }
    .beauty {
      background-image: url('../../assets/imgs/home/ta/ta-beauty.jpg');
    }
    .vendor {
      background-image: url('../../assets/imgs/home/ta/ta-vendor.jpg');
    }
    .shop {
      background-image: url('../../assets/imgs/home/ta/ta-shop.jpg');
    }
    .handmade {
      background-image: url('../../assets/imgs/home/ta/ta-handmade.jpg');
    }
    .cooperate {
      background-image: url('../../assets/imgs/home/ta/ta-cooperate.jpg');
    }
  }
}
.compare {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-wrapper, .table-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 100px;
      width: 100%;
      max-width: 1000px;
      @media screen and (max-width: 768px) {
        padding: 0;
          }
      .table-title {
        font-size: 20px;
        color: #424242;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 16px;
        width: 33%;
        @media screen and (max-width: 768px) {
            font-size: 15px;
          }

      }
      .table-text {
        height: 44px;
        text-align: center;
        line-height: 44px;
        margin: 5px 0;
        font-size: 16px;
        width: 33%;
        @media screen and (max-width: 768px) {
            font-size: 15px;
          }
          @media screen and (max-width: 390px) {
            font-size: 14px;
          }
      }
      .label {
        background-color: #FBCA5A;
        border-radius: 50px 0 0 50px;
      }
      .others {
        background-color: #EEF6FD;
      }
      .insto {
        background-color: #DDE9FA;
        font-weight: 500;
        color: #2371FF;
        border-radius: 0 50px 50px 0;
      }
    }
  }
</style>
