<template>
  <div id="ap-event-pricing">
    <transition name="fade">
      <h1 class="title-lg">
      賣家無需申請，無需付費<br>
      即可享有 Apple Pay 收款功能
    </h1>
    </transition>
    <a href="https://tap.ins.to/pricing" target="_blank" id="ap-click-pricing-url">
      <button class="action-button outline-style">
        查看牌價費率
      </button>
    </a>
</div>
</template>

<script>
export default {
  
    methods: {
      download() {
      const modal = document.getElementById('ap-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/apple-pay.scss";

#ap-event-pricing {
  padding: 80px 80px;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 1552px) {
    padding: 60px 100px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
  h1 {
    margin-bottom: 20px;
  }
}


</style>
