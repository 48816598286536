<template>
  <div id="us-faq">
    <section>
      <h2>FAQs</h2>
    <div class=faq-wrapper>
      <div class="faq-list" v-for="(item, index) in items" :key="index" @click="toggleAccordion(index)" :id="'us-i-faq-q'+index">
    <div class="faq-title-wrapper accordion" :id="'us-i-faq-q'+index">
      <h3>{{ item.title }}</h3>
      <div class="accordion-icon bg-contain" :class="{ 'icon-active': activeAccordion === index }">
        <svg xmlns="http://www.w3.org/2000/svg" width="37.4" height="37.4" xml:space="preserve">
          <path d="M.7 18.7h18m0 0h18m-18 0V.7m0 18v18" fill="none" stroke="#4a4a4a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="panel" :key="index" :class="{ 'active': activeAccordion === index }">
        {{ item.content }}
      <div v-html="item.url" style="display:inline"></div>
      <img :src="item.img">
    </div>
    </div>
    </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "How to become an INSTO Tap seller?", content: "Simply download the INSTO Tap App, apply to be a seller within the app, wait for bank approval via email, and you're ready to start selling." },
        { title: "Is there a fee for using INSTO Tap?", content: "Using INSTO Tap incurs no monthly fees. We only charge a transaction fee of 2.9%+30¢, with no hidden costs." },
        { title: "Which credit cards does INSTO Tap support?", content: "We currently support Visa / master / JCB / American Express / Discover / Diners Club / Maestro."},
        { title: "How long does it take to receive payments with INSTO Tap?", content: "The time for funds to be credited when using INSTO Tap is determined by Quantum ePay's seller rules." }
      ],
      activeAccordion: null
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/us-layout.scss";

#us-faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    margin: 0;
    font-size: 32px;
  }

  .faq-list {
    width: 100%;
    border-bottom: 1px solid #4a4a4a;
    padding: 12px 0;
    cursor: pointer;
    h3 {
      margin: 0;
      font-size: 22px;
      font-weight: normal;
    }
  }

  .accordion {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.accordion-icon {
  cursor: pointer;
  margin-left: 20px;
  transition: all .3s ease-in-out;
  flex-shrink: 0;
}

.panel {
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 18px;
  line-height: 1.5;
}

.active {
  max-height: 100%;
}

.active,
.accordion:hover {
  font-weight: blod;

  .accordion-icon {
    transform: rotate(45deg);
  }
}

.action-button {
  margin-top: 50px;
}

</style>
