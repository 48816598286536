<template>
  <div id="fcc-event-ta">
    <h1 class="title-md">
      走到哪收到哪，多種情境都適用
    </h1>
    <div class="ta-wrapper">
      <div class="ta-container">
        <div class="ta-img bg-contain ta-taxi"></div>
         <div class="ta-title title-sm">計程車收款</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-travel"></div>
          <div class="ta-title title-sm">包車旅遊</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-lawyer"></div>
          <div class="ta-title title-sm">專業律師服務</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-market"></div>
        <div class="ta-title title-sm">市集活動</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-buy"></div>
        <div class="ta-title title-sm">代購服務</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-teacher"></div>
        <div class="ta-title title-sm">家教服務</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-art"></div>
        <div class="ta-title title-sm">手工藝</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-tarot"></div>
        <div class="ta-title title-sm">塔羅占卜</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-design"></div>
          <div class="ta-title title-sm">商品訂製</div>
      </div>
      <div class="ta-container">
        <div class="ta-img bg-contain ta-cosme"></div>
          <div class="ta-title title-sm">美容美業</div>
      </div>
    </div>
    <button class="action-button" v-on:click="download()" id="fcc-click-ta-acbtn">
      立即註冊成為賣家
    </button>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('fcc-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/foreign-credit-card.scss";

#fcc-event-ta {
  padding: 80px 0;
  @media screen and (max-width: 1552px) {
    padding: 60px 80px;
  }
  @media screen and (max-width: 1024px) {
    padding: 80px 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
  @media screen and (max-width: 415px) {
    padding: 20px;
  }
  .ta-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    .ta-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 15px 6px;
      @media screen and (max-width: 1024px) {
        margin: 15px;
      }
      .ta-img {
        height: 250px;
        width: 250px;
        background-color: black;
        margin-bottom: 20px;
        @media screen and (max-width: 1332px) {
          height: 200px;
          width: 200px;
        }
        @media screen and (max-width: 1024px) {
          height: 124px;
          width: 124px;
          border-radius: 50%;
          border: 10px solid white;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.ta-taxi {
  background-image: url('../../assets/imgs/event/foreign-card/ta-taxi.png');
}

.ta-travel {
  background-image: url('../../assets/imgs/event/foreign-card/ta-travel.png');
}

.ta-guide {
  background-image: url('../../assets/imgs/event/foreign-card/ta-guide.png');
}

.ta-market {
  background-image: url('../../assets/imgs/event/foreign-card/ta-market.png');
}

.ta-teacher {
   background-image: url('../../assets/imgs/event/foreign-card/ta-teacher.png');
}

.ta-tarot {
  background-image: url('../../assets/imgs/event/foreign-card/ta-tarot.png');
}

.ta-cosme {
  background-image: url('../../assets/imgs/event/foreign-card/ta-cosme.png');
}

.ta-design {
  background-image: url('../../assets/imgs/event/foreign-card/ta-design.png');
}

.ta-buy{
  background-image: url('../../assets/imgs/event/foreign-card/ta-buy.png');
}

.ta-lawyer{
  background-image: url('../../assets/imgs/event/foreign-card/ta-lawyer.png');
}

.ta-art{
  background-image: url('../../assets/imgs/event/foreign-card/ta-art.png');
}
</style>
