<template>
  <div id="fcc-event-apply">
    <h1 class="title-md">輕鬆申請4步驟</h1>
    <div class="apply-steps-wrapper">
      <div class="step-wrapper">
        <div class="step-img bg-contain step01"></div>
        <div class="step-title">下載App</div>
      </div>
      <div class="step-wrapper">
          <div class="step-img bg-contain step02"></div>
          <div class="step-title">填寫資料</div>
      </div>
        <div class="step-wrapper">
        <div class="step-img bg-contain step03"></div>
        <div class="step-title">送出審核</div>
      </div>
        <div class="step-wrapper">
        <div class="step-img bg-contain step04"></div>
        <div class="step-title">審核成功</div>
      </div>
    </div>
    <div class="action-button-wrapper">
      <a href="https://tap.ins.to/products/apply-example-personal" target="_blank" id="fcc-click-apply-exurl">
        <button class="action-button">
          申請個人賣家範例
        </button>
      </a>
       <button class="action-button" v-on:click="download()" id="fcc-click-apply-acbtn">
        立即註冊成為賣家
      </button>
    </div>
    <p>注意事項
      <ol>
        <li>
          INSTO 所有交易為了保障買賣雙方交易安全，信用卡付款時一律進行3D驗證（簡訊驗證），使用國外卡的交易也相同。
        </li>
        <li>
          如果該國外卡的所屬銀行能正確完成3D驗證程序，卡片即可正常刷用。因每個國家/每家銀行對驗證機制的支援程度有所不同，因此國外卡可能會有無法完成3D驗證、刷卡失敗的狀況，還請知悉諒解。
        </li>
        <li>
          INSTO Tap 國外卡收款收取的卡種為VISA、MASTERCARD、JCB；銀聯卡目前沒有收取。
        </li>
      </ol>
    </p>
  </div>
</template>

<script>
export default {
    methods: {
      download() {
      const modal = document.getElementById('fcc-event-modal')
      modal.classList.add('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/foreign-credit-card.scss";

#fcc-event-apply {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1552px) {
    padding: 60px 100px;
  }
  @media screen and (max-width: 1024px) {
    padding: 50px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
  .apply-steps-wrapper { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
      width: 800px;
    }
    @media screen and (max-width: 768px) {
      width: 400px;
    }
    @media screen and (max-width: 3px) {
      width: 100%;
    }
    
    .step-wrapper {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       .step-img {
        height: 150px;
        width: 150px;
        margin: 0 10px;
        background-color: white;
       }
       .step-title {
        margin-top: 12px;
        font-size: 18px;
       }
    }
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 40px;
    text-align: left;
    max-width: 800px;
  }
}

.step01 {
  background-image: url('../../assets/imgs/event/foreign-card/fc-apply-step01.png');
}

.step02 {
  background-image: url('../../assets/imgs/event/foreign-card/fc-apply-step02.png');
}

.step03 {
  background-image: url('../../assets/imgs/event/foreign-card/fc-apply-step03.png');
}

.step04 {
  background-image: url('../../assets/imgs/event/foreign-card/fc-apply-step04.png');
}

.action-button-wrapper {
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  button {
    margin-left: 12px;
    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin: 6px;
    }
  }
}

</style>
