<template>
  <div id="customers-mobile">
    <h5>賣家體驗回饋</h5>
    <div class="customers-wrapper">
      <carousel
        paginationActiveColor="#00c8ff"
        :perPage="1"
        autoplay
        :autoplayTimeout="4000"
        easing="ease"
        loop
      >
        <slide>
          <div class="slide-wrapper">
            <div class="customer-container">
              <div class="customer-photo"></div>
              <div class="comma"></div>
              <div class="customer-name">喆律法律事務所</div>
              <div class="customer-feedback">INSTO付款連結可提供客戶預先付款或現場信用卡結帳，解決遲遲收不到諮詢費用的困擾</div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="slide-wrapper">
            <div class="customer-container">
              <div class="customer-photo taipei-fitness"></div>
              <div class="comma"></div>
              <div class="customer-name">台北健身中心</div>
              <div class="customer-feedback">結帳方便快速，使用情境也很彈性，不論在店內或遠距傳送都很方便</div>
            </div>
          </div>
        </slide>
        <slide>
          <div class="slide-wrapper">
            <div class="customer-container">
              <div class="customer-photo elsa"></div>
              <div class="comma"></div>
              <div class="customer-name">雲清藝術</div>
              <div class="customer-feedback">海內外客人結帳不僅方便直覺，還同時提升專業形象</div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#customers-mobile {
  position: relative;
  background: $lightBlue;
  padding-top: 80px;
  height: 270px;
}

.customers-wrapper {
  position: absolute;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.customer-container {
  position: relative;
 width: 260px;
  height: 220px;
  border-radius: 20px;
  box-shadow: 0 8px 20px 0 $shadowBlue;
  background-color: $white;
  padding: 30px;
  text-align: center;
  cursor: default;
  margin: 0 6px;
  .customer-photo {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    border: 3px solid $gradientdeepGreen;
    border-radius: 50%;
    background: $white;
     background-image: url("../../assets/imgs/home/customer/zhe-lu.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .elsa {
    background-image: url("../../assets/imgs/home/customer/elsa.jpg");
  }
  .taipei-fitness {
    background-image: url("../../assets/imgs/home/customer/taipei-fitness.jpg");
  }
  .comma {
    margin: 20px auto 12px auto;
    width: 30px;
    height: 25px;
    background-image: url("../../assets/imgs/home/comma.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .customer-name {
    font-size: 18px;
    margin-bottom: 18px;
  }
  .customer-feedback {
    color: $subTitle;
  }
}

.slide-wrapper {
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ {
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin-top: 0 !important;
      button.VueCarousel-dot {
        padding: 0 !important;
        width: 30px !important;
        height: 5px !important;
        border-radius: 100px !important;
        background-clip: unset !important;
        box-sizing: unset !important;
        margin: 0 3px;
        outline: none !important;
      }
    }
  }
}
</style>
