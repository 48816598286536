
<template>
  <div id="us-individual">
    <USHeader></USHeader>
    <section class="main">
      <div class="left">
        <h1>The best choice for individual sellers to receive payments.</h1>
        <h2>An app that makes doing business easy, whether it's remote payments or face-to-face transactions.</h2>
        <a class="button">Free download</a>
        <router-link to="/us/business" target="_blank" class="link">Not an individual seller? Try business seller!</router-link>
        <div class="downloads app">
        <div class="app-logo bg-contain"></div>
        <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener">
          <div class="ios-logo bg-contain"></div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank" rel="noopener">
          <div class="android-logo bg-contain"></div>
        </a>
      </div>
      </div>
      <div class="right bg-cover">
      </div>
    </section>
    <section class="features">
      <div class="features-wrapper">
        <div class="feature-content">
          <div class="feature-label">
          Send Bill
        </div>
        <h1 class="feature-title">
          Sellers can easily complete remote transactions by sending links.
        </h1>
        <div class="feature-text">
          Upon a successful remote payment by a buyer, you will receive a confirmation message via email and mobile notification.
        </div>
        </div> 
        <img src="../assets/imgs/us/sendbill-i.png" class="sendbill-img">
      </div>
      <div class="features-wrapper">
        <div class="feature-content">
          <div class="feature-label">
            QR Pay
          </div>
        <h1 class="feature-title">
          Buyers can scan your dedicated payment QR code and enter the payment amount themselves.
        </h1>
        <div class="feature-text">
          Your buyers can simply scan a code for payments, without installing an app. iPhone users can use the built-in camera to scan the code, Android users can use any scanning apps.
        </div>
        </div>
        <img src="../assets/imgs/us/qrpay-i.png" class="qr-pay-img">
      </div>
    </section>
    <USFee></USFee>
    <section class="steps">
      <h2>Join us in 4 steps</h2>
      <div class="step-wrapper">
        <div class="step">
          <div class="step-img bg-cover step01"></div>
          <div class="step-title">Free download</div>
        </div>
        <div class="step">
          <div class="step-img bg-cover step02"></div>
          <div class="step-title">Sign up for free</div>
        </div>
        <div class="step">
          <div class="step-img bg-cover step03"></div>
          <div class="step-title">Apply as a seller</div>
          <div class="step-desc">powered by
            <a href="https://quantumepay.com/" target="_blank">
              <img src="../assets/imgs/us/quantum-epay.png" class="qp-logo">
            </a>
          </div>
        </div>
        <div class="step">
          <div class="step-img bg-cover step04"></div>
          <div class="step-title">Approved and ready to go!</div>
        </div>
      </div>
      <div class="notice-wrapper">
        <div class="notice bg-contain"></div>
        You will be notified by E-mail once done.
      </div>
    </section>
    <USFaq></USFaq>
    <USContact></USContact>
    <USFooter></USFooter>
  </div>
</template>

<script>
import USHeader from "@/components/us/USHeader.vue";
import USFee from "@/components/us/USFee.vue";
import USFaq from "@/components/us/USFaq.vue";
import USContact from "@/components/us/USContact.vue";
import USFooter from "@/components/us/USFooter.vue";

  export default {
    name: 'US-Indiviual',
    metaInfo: {
      title: 'Click, Tap, Collect',
      titleTemplate: 'INSTO Tap US | %s ',
      htmlAttrs: {
      lang: 'en-US'
      }
    },
    components: {
      USHeader,
      USFee,
      USFaq,
      USContact,
      USFooter
    },
    data() {
    return {
      items: [
        { title: "How to become an INSTO Tap seller?", content: "Simply download the INSTO Tap App, apply to be a seller within the app, wait for bank approval via email, and you're ready to start selling." },
        { title: "Is there a fee for using INSTO Tap?", content: "Using INSTO Tap incurs no monthly fees. We only charge a transaction fee of 2.9%+30¢, with no hidden costs." },
        { title: "Which credit cards does INSTO Tap support?", content: "We currently support Visa / master / JCB / American Express / Discover / Diners Club / Maestro."},
        { title: "How long does it take to receive payments with INSTO Tap?", content: "The time for funds to be credited when using INSTO Tap is determined by Quantum ePay's seller rules." }
      ],
      activeAccordion: null
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    }
  }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/us-layout.scss";

  #us-individual {
    margin-left: 0;
    width: 100%;
    cursor: default;
    position: relative;
  }


  .main {
    background-image: linear-gradient(135deg, #00C7FF, #2581C5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        padding: 0;
    }
    .left {
      max-width: 450px;
      @media screen and (max-width: 1024px) {
        max-width: 100%;
        padding: 30px;
      }
      h1 {
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 24px;
        margin-bottom: 40px;
      }
      .link {
        display: block;
        font-size: 16px;
        font-weight: normal;
        margin-top: 20px;
        color: white;
        text-decoration: underline;
        transition: all .3s ease-in-out;
        &:hover {
          transform: translate(6px);
        }
      }
      .app {
        justify-content: flex-start !important;
      }
      .button {
        background-color: transparent;
        border: 1px solid white;
        width: 100%;
        text-align: center;
        height: 50px;
        font-size: 20px;
      }
    }
    .right {
      flex-shrink: 0;
      width: 500px;
      height: 500px;
      background-color: #00C7FF;
      border-radius: 50px;
      margin-left: 50px;
      background-image: url('../assets/imgs/us/seller-i-main.jpg');
      @media screen and (max-width: 1024px) {
        width: 100%;
        border-radius: 0;
        margin-left: 0;
      }
    }
  }

  .features {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .features-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
          &:last-child {
            flex-direction: column-reverse !important;
          }
        } 
        &:last-child {
          flex-direction: row-reverse;
          margin-top: 80px;
        }
        .feature-content {
          margin-right: 0;
          margin-left: 30px;
        }
      .feature-content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        max-width: 500px;
        margin-right: 30px;
        .feature-label {
          font-size: 20px;
          font-weight: bold;
          color: #00C7FF;
        }
        h1 {
          font-size: 32px;
          line-height: 1.2;
          margin: 16px 0;
          letter-spacing: 0.2;
        }
        .feature-text {
          font-size: 18px;
          line-height: 1.35;
        }
      }
      .sendbill-img {
          width: 450px;
          @media screen and (max-width: 1024px) {
            width: 380px;
          }
          @media screen and (max-width: 768px) {
            width: 80%;
            margin: 0 auto;
          }
        }
        .qr-pay-img {
          width: 500px;
          @media screen and (max-width: 1024px) {
            width: 360px;
          }
          @media screen and (max-width: 768px) {
            width: 80%;
            margin: 0 auto;
          }
        }
    }
  }

  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 32px;
    }
    .notice-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .notice {
        width: 14px;
        height: 14px;
        margin-right: 4px;
        background-image: url('../assets/imgs/us/star.svg');
      }
      font-size: 14px;
      font-weight: normal;
    }
    .step-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 20px 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
      .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        width: 160px;
        @media screen and (max-width: 768px) {
          margin: 0;
          width: 100%;
          margin: 10px 0;
        }
        .step-img {
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
          @media screen and (max-width: 768px) {
            width: 60px;
            height: 60px;
            margin-bottom: 16px;
          }
        }
        .step-title {
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          color: #00c7ff;
        }
        .step-desc {
          font-size: 15px;
          margin: 12px 0;
          .qp-logo {
            height: 20px;
          }
        }
        .step01 {
          background-image: url('../assets/imgs/us/i-step1.svg');
        }
        .step02 {
          background-image: url('../assets/imgs/us/i-step2.svg');
        }
        .step03 {
          background-image: url('../assets/imgs/us/i-step3.svg');
        }
        .step04 {
          background-image: url('../assets/imgs/us/i-step4.svg');
        }
      }
    }
  }

  
  .ios-logo {
    background-image: url("../assets/imgs/download-app-store.png");
  }

  .android-logo {
    background-image: url('../assets/imgs/download-google-play.png');
  }

  .app-logo {
    background-image: url("../assets/imgs/app-logo.png");
  }

</style>
