<template>
    <div id="cookie-alert" v-if="value === null" v-show="$route.query.app !== '1'&& !$route.path.includes('us/')" >
      <div class="alert-text">
        為提供您最佳個人化且即時的服務，本網站透過使用Cookies記錄與存取您的瀏覽使用訊息。當您使用本網站，即表示您同意Cookies技術支援。更多資訊請參閱
        <a
          href="/terms/privacy.html"
          class="link"
          target="_blank"
          >隱私權政策</a
        >。
      </div>
      <div class="alert-close" @click="acceptCookie()">
        <div class="close"></div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    this.value = this.$cookie.get("cacknowledge");
    // console.log(this.value)
  },
  methods: {
    acceptCookie() {
      // this.$cookie.set("cacknowledge");
      document.cookie = "cacknowledge = 1;secure";
      this.value = this.$cookie.get("cacknowledge");
      // console.log(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#cookie-alert {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-image: linear-gradient(267deg, #32deff, #2371ff);
  color: $white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .alert-text {
    max-width: 540px;
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $deepBlue;
    }
  }
}

.alert-close {
  position: relative;
  margin-left: 16px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.close {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0.53;
  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    top: 5px;
    left: 15px;
    content: " ";
    height: 20px;
    width: 1.5px;
    background-color: $white;
    border-radius: 30px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
</style>
