<template>
  <div id="main-banner">
    <main>
      <div class="main-wrapper">
        <h1>Click, Tap, Collect</h1>
        <h1 class="slogan">輕碰卡片、輕鬆收款、你的行動收款App</h1>
        <div class="downloads">
          <a
            href="https://apps.apple.com/tw/app/id1525691104"
            target="_blank"
            rel="noopener"
          >
            <div class="ios-logo" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.installments.instotap"
            target="_blank"
            rel="noopener"
          >
            <div class="android-logo" />
          </a>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#main-banner {
  padding: 20px;
  @media screen and (max-width: 500px) {
    padding: 0;
  }

  main {
    position: relative;
    width: 100%;
    height: 550px;
    border-radius: 20px;
    color: $white;
    overflow: hidden;
    background-color: aliceblue;
    background-size: cover;
    -o-background-size:cover; 
    -moz-background-size:cover; 
    background-position: center;
    animation: bg-1024 infinite 6s alternate-reverse ease-in-out;
    background-attachment: scroll;
    background-repeat:no-repeat;
    @media screen and (max-width: 1500px) {
      height: 500px;
    }
     @media screen and (max-width: 1400px) {
      height: 400px;
    }
       
    @media screen and (max-width: 500px) {
      border-radius: 0;
      height: 400px;
      animation: none;
      animation: bg-500 infinite 6s alternate-reverse ease-in-out;
    }

    @media screen and (max-width: 375px) {
      animation: bg-375 infinite 6s alternate-reverse ease-in-out;
    }

    .main-wrapper {
      position: absolute;
      bottom: 40px;
      left: 40px;
      z-index: 3;
      @media screen and (max-width: 500px) {
        bottom: 30px;
        left: 30px;
      }
      h1,
      h2 {
        font-weight: normal;
        line-height: auto;
        margin: 0;
        letter-spacing: 1px;
      }
      h1 {
        font-size: 24px;
        margin-top: 6px;
        @media screen and (max-width: 500px) {
          text-shadow: black 0.1em 0.1em 0.2em;
        }
      }
    }
  }
}

@keyframes bg-1024 {
    0% {
       background-image: url("../../assets/imgs/home/banner/main-banner-1024.jpg");
    }
    40% {
       background-image: url("../../assets/imgs/home/banner/main-banner2-1024.jpg");
    }

    60% {
       background-image: url("../../assets/imgs/home/banner/main-banner2-1024.jpg");
    }
    100% {
       background-image: url("../../assets/imgs/home/banner/main-banner-1024.jpg");
    }
    
  }

  @keyframes bg-500 {
    0% {
       background-image: url("../../assets/imgs/home/banner/main-banner-500.jpg");
    }
    40% {
       background-image: url("../../assets/imgs/home/banner/main-banner2-500.jpg");
    }

    60% {
       background-image: url("../../assets/imgs/home/banner/main-banner2-500.jpg");
    }
    100% {
       background-image: url("../../assets/imgs/home/banner/main-banner-500.jpg");
    }
    
  }


  @keyframes bg-375 {
    0% {
       background-image: url("../../assets/imgs/home/banner/main-banner-375.jpg");
    }
    40% {
       background-image: url("../../assets/imgs/home/banner/main-banner2-375.jpg");
    }

    60% {
       background-image: url("../../assets/imgs/home/banner/main-banner2-375.jpg");
    }
    100% {
       background-image: url("../../assets/imgs/home/banner/main-banner-375.jpg");
    }
    
  }

.downloads {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .ios-logo {
    height: 39px;
    width: 126px;
    background-image: url("../../assets/imgs/download-app-store.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 8px;
  }

  .android-logo {
    height: 39px;
    width: 126px;
    background-image: url("../../assets/imgs/download-google-play.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.slogan {
  @media screen and (max-width: 600px) {
    width: 250px;
  }
}
</style>
