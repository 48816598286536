<template>
  <div id="page-not-found"></div>
</template>

<script>

export default {
  created: function() {
        // Redirect outside the app using plain old javascript
        window.location.href = "/error404.html";
    }
};
</script>

<style lang="scss" scoped>
// @import "./src/assets/sass/main.scss";

</style>
