<template>
  <div id="event-apple-pay">
    <ApEventMain></ApEventMain>
    <ApEventProduct></ApEventProduct>
    <ApEventPricing></ApEventPricing>
    <ApEventApply></ApEventApply>
    <ApEventFaq></ApEventFaq>
    <ApEventFooter></ApEventFooter>
    <ApEventModal></ApEventModal>
  </div>
</template>

<script>
  import ApEventMain from "@/components/apple-pay/ApEventMain.vue"
  import ApEventProduct from "@/components/apple-pay/ApEventProduct.vue"
  import ApEventPricing from "@/components/apple-pay/ApEventPricing.vue"
  import ApEventApply from "@/components/apple-pay/ApEventApply.vue"
  import ApEventFaq from "@/components/apple-pay/ApEventFaq.vue"
  import ApEventFooter from "@/components/apple-pay/ApEventFooter.vue"
  import ApEventModal from "@/components/apple-pay/ApEventModal.vue"

  export default {
    name: 'Apple-pay',
    metaInfo: {
    title: '免申請、免付費，使用 Apple Pay 收款超簡單! ',
    titleTemplate: 'INSTO Tap | %s ',
  },
    components: {
      ApEventMain,
      ApEventProduct,
      ApEventPricing,
      ApEventApply,
      ApEventFaq,
      ApEventFooter,
      ApEventModal
    }
}
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/apple-pay.scss";

  #event-apple-pay {
    margin-left: 0;
    cursor: default;
    position: relative;
  }

  .router-view-layout {
    width: 100%;
  }

  .show {
  display: block;
}
</style>
