<template>
  <div id="foreign-credit-card">
    <FccEventHeader></FccEventHeader>
    <FccEventMain></FccEventMain>
    <FccEventTA></FccEventTA>
    <FccEventProduct></FccEventProduct>
    <FccEventPricing></FccEventPricing>
    <FccEventApply></FccEventApply>
    <FccEventFaq></FccEventFaq>
    <FccEventFooter></FccEventFooter>
    <FccEventModal></FccEventModal>
    <a href="https://tap.ins.to/products/apply-example-business" target="_blank">
      <div id="fcc-event-business">
          <div class="business-icon bg-contain"></div>
          <div class="business-text">企業賣家<br>如何申請</div>
      </div>
    </a>
  </div>
</template>

<script>
import FccEventMain from "@/components/foreign-credit-card/FccEventMain.vue"
import FccEventHeader from "@/components/foreign-credit-card/FccEventHeader.vue"
import FccEventTA from "@/components/foreign-credit-card/FccEventTA.vue"
import FccEventProduct from "@/components/foreign-credit-card/FccEventProduct.vue"
import FccEventPricing from "@/components/foreign-credit-card/FccEventPricing.vue"
import FccEventApply from "@/components/foreign-credit-card/FccEventApply.vue"
import FccEventFaq from "@/components/foreign-credit-card/FccEventFaq.vue"
import FccEventFooter from "@/components/foreign-credit-card/FccEventFooter.vue"
import FccEventModal from "@/components/foreign-credit-card/FccEventModal.vue"

export default {
  name:'foreign-credit-card',
  metaInfo: {
    title: '個人賣家信用卡收款服務，國內外信用卡都能收！',
    meta: [
        {
          property: 'og:title',
          content: '信用卡收款App，免費申請，免月租費，隨時隨地，即時收款！',
          template: chunk => `INSTO Tap |${chunk}`,
          vmid: 'og:title'
        }
      ]
   },
   methods: {
    download() {
      const modal = document.getElementById('fcc-event-modal')
      modal.classList.add('show')
    }
  },
  components: {
    FccEventHeader,
    FccEventMain,
    FccEventTA,
    FccEventProduct,
    FccEventPricing,
    FccEventApply,
    FccEventFaq,
    FccEventFooter,
    FccEventModal
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
@import "./src/assets/sass/foreign-credit-card.scss";

#foreign-credit-card {
  position: relative;
  cursor: default;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

.router-view-layout {
  width: 100%;
}

#fcc-event-business {
  right: 0px;
  bottom: 120px;
  cursor: pointer;
  width: 82px;
  height: 82px;
  position: fixed;
  background-color: #fff;
  padding: 12px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
  transition: all .3s ease-in-out;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.1);
  }
  .business-icon{
    background-image: url('../assets/imgs/event/foreign-card/business-icon.svg');
    width: 22px;
    height: 22px;
    margin-bottom: 4px;
    background-size: contain;
  }
  .business-text {
    font-size: 13px;
    font-weight: bold;
    color: black;
  }
}

.show {
  display: block;
}

</style>
