<template>
  <header  v-show="$route.query.app !== '1' && !$route.path.includes('event') && !$route.path.includes('us/')">
    <div class="app-logo" />
    <div class="sidebar-wrapper">
      <router-link to="/">
        <img src="../assets/imgs/tap-logo.svg" height="32" alt="logo" />
      </router-link>
      <div class="mobile-sidebar" v-on:click="toggle = !toggle">
        <div class="sidebar" />
        <div class="sidebar" />
      </div>
    </div>
    <transition name="fade">
      <div class="sidebar-modal" v-show="toggle">
        <div class="tab" id="mobile-collect-btn" v-on:click="show = !show">多元收款
          <div class="mobile-collect-selector" :class="{'height': show}">
            <router-link to="/products/insto-tap" class="sub-tab" id="header-tab-tap">感應收款</router-link>
            <router-link to="/products/insto-me" class="sub-tab" id="header-tab-qr">商店收款碼</router-link>
            <router-link to="/products/insto-collect" class="sub-tab" id="header-tab-send">遠距收款</router-link>
            <router-link to="/products/more-services" class="sub-tab" id="header-tab-more">更多功能</router-link>
          </div>
        </div>
        <router-link to="/products/apply-example-personal" class="tab">如何申請</router-link>
        <router-link to="/pricing" class="tab">計費方案</router-link>
        <router-link to="/contact-us" class="tab">聯絡我們</router-link>
        <a ref="nooper" href="https://support.ins.to/hc/zh-tw" class="tab" target="_blank">常見問題</a>
        <a href="https://merchant.ins.to/" target="_blank" class="seller-login mobile">賣家管理後台<img src="../assets/imgs/window-link.svg" alt="seller login link"></a>
      </div>
    </transition>
    <div class="tab-wrapper">
      <div class="tab" id="collect-btn">多元收款
        <div class="collect-selector">
          <router-link to="/products/insto-tap" class="sub-tab" id="header-m-tab-tap">感應收款</router-link>
          <router-link to="/products/insto-me" class="sub-tab" id="header-m-tab-qr">商店收款碼</router-link>
          <router-link to="/products/insto-collect" class="sub-tab" id="header-m-tab-send">遠距收款</router-link>
          <router-link to="/products/more-services" class="sub-tab" id="header-m-tab-more">更多功能</router-link>
        </div>
      </div>
      <router-link to="/products/apply-example-personal" class="tab">如何申請</router-link>
      <router-link to="/pricing" class="tab">計費方案</router-link>
      <router-link to="/contact-us" class="tab">聯絡我們</router-link>
      <a ref="nooper" href="https://support.ins.to/hc/zh-tw" class="tab" target="_blank">常見問題</a>
      <a href="https://merchant.ins.to/" target="_blank" class="seller-login" height="16px" width="16px">賣家管理後台<img src="../assets/imgs/window-link.svg" alt="seller login link"></a>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
      show: false
    };
  },
  watch: {
    '$route' () {
      this.toggle = false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
header {
  height: 100vh;
  min-width: 220px;
  background: #ffffff;
  border-right: 1px solid $mainBlue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 998;
  @media screen and (max-width: 1000px) {
    height: 60px;
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid $mainBlue;
    display: block;
    padding-right: 30px;
    img {
      height: 26px;
    }
    .app-logo,
    .tab-wrapper {
      display: none;
    }
    .sidebar-wrapper {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  img {
    margin-bottom: 50px;
    @media screen and (max-width: 1000px) {
      margin-bottom: 0;
      margin-left: 40px;
    }
  }
  .tab {
    display: block;
    height: 50px;
    text-align: center;
    line-height: 50px;
    &:focus {
      color: $mainBlue;
    }
  }
  a {
    text-decoration: none;
    font-size: 14px;
    color: $textBlack;
  }
}

.app-logo {
  min-height: 45px;
  min-width: 45px;
  background-image: url("../assets/imgs/app-logo.png");
  background-size: contain;
  margin-bottom: 30px;
  box-shadow: 0 6px 5px 0 rgba(13, 138, 237, 0.3);
  border-radius: 12px;
}

.mobile-sidebar {
  cursor: pointer;
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
  }
  .sidebar {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 50;
    background: $deepBlue;
    margin-bottom: 4px;
    transition: all ease-in-out .5s;
  }
}

.showMenu {
  display: block;
}

.sidebar-modal {
  display: none;
  width: 100%;
  background: $white;
  position: absolute;
  top: 60px;
  left: 0;
  padding: 0 0 20px 0;
  box-shadow: 0 2px 8px 0 $boxShadow;
  border-radius: 0 0 20px 20px;
  z-index: 999;
  transition: all 5s ease-in-out;
  @media screen and (max-width: 1000px) {
    display: block;
    a {
      padding-left: 0 !important;
    }
  }
  a {
    width: 100%;
    padding-left: 30px;
    line-height: 60px;
    height: 60px;
    &:hover {
      background: $mainBlue;
      color: white;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.seller-login {
  display: flex;
  margin-top: 20px;
  font-size: 13px;
  color: $mainBlue;
  align-items: center;
  justify-content: center;
  img {
    height: 16px;
    margin-left: 6px;
    margin-bottom: 0;
  }
}

.mobile {
  margin-top: 0;
}

#collect-btn {
  position: relative;
  cursor: pointer;
  &:hover, &:active {
    .collect-selector {
      display: block;
    } 
  }
}

.collect-selector {
  display: none;
  position: absolute;
  top: 0;
  left: 220px;
  background: white;
  box-shadow: 0 6px 5px 0 rgba(13, 138, 237, 0.3);
  padding: 20px;
  border-radius: 0 10px 10px 0;
  .sub-tab {
    text-align: center;
    display: block;
    height: 50px;
    width: 100px;
  }
}

.tab-wrapper {
  width: 100%;
}

#mobile-collect-btn {
  cursor: pointer;
  height: auto !important;
}

.mobile-collect-selector {
  overflow: hidden;
  height: 0;
  transition: height 1s ease-in-out;

  .sub-tab {
    cursor: pointer;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
  }
}

.height {
  height: 200px;
  background: #F6FAFB;
}

</style>
