<template>
  <div id="fcc-event-modal">
    <div class="top-wrapper">
      <div class="title-wrapper">
        <h1>
          立即下載 INSTO App 體驗
        </h1>
        <h2>
          下載、註冊、申請免費
        </h2>
      </div>
      <div id="fcc-event-modal-closed" class="closed bg-contain" v-on:click="closed()"></div>
    </div>
    <img src="../../assets/imgs/event/foreign-card/app-phone.png" class="phone-img">
    <div class="downloads">
      <div class="app-logo bg-contain"></div>
      <a href="https://apps.apple.com/tw/app/id1525691104" target="_blank" rel="noopener" id="fcc-modal-iosbtn">
        <div class="ios-logo bg-contain"></div>
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.installments.instotap" target="_blank" rel="noopener" id="fcc-modal-androidbtn">
        <div class="android-logo bg-contain"></div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
    closed() {
      const modal = document.getElementById('fcc-event-modal')
      modal.classList.remove('show')
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "./src/assets/sass/main.scss";
  @import "./src/assets/sass/foreign-credit-card.scss";

#fcc-event-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px 10px 40px 10px;
  width: 452px;
  border-radius: 30px;
  background-color: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 768px) {
    top: 80px;
    width: 95%;
    border-radius: 20px;
    padding: 80px 10px 40px 10px;
  }
}

.title-wrapper {
  h1,h2 {
    text-align: center;
    margin: 0;
  }
  h2 {
    font-weight: normal;
  }
}

.closed {
  position: absolute;
  top: 26px;
  right: 26px;
  width: 28px;
  height: 28px;
  background-image: url('../../assets/imgs/event/foreign-card/close.svg');
  z-index: 999;
  cursor: pointer;
}

.app-logo {
  background-image: url("../../assets/imgs/app-logo.png");
}

.android-logo {
  background-image: url("../../assets/imgs/download-google-play.png");
}

.ios-logo {
  background-image: url("../../assets/imgs/download-app-store.png");
}

.phone-img {
  width: auto;
  height: 280px;
}

.downloads {
  margin: 20px 0 0 0 !important;
  justify-content: center !important;
}


</style>
