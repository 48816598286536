import { render, staticRenderFns } from "./FccEventFooter.vue?vue&type=template&id=c78e1da4&scoped=true&"
import script from "./FccEventFooter.vue?vue&type=script&lang=js&"
export * from "./FccEventFooter.vue?vue&type=script&lang=js&"
import style0 from "./FccEventFooter.vue?vue&type=style&index=0&id=c78e1da4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c78e1da4",
  null
  
)

export default component.exports